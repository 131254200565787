import {
  GET_ORGANIZATION_DOMAIN_LIST_FAILURE,
  GET_ORGANIZATION_DOMAIN_LIST_SUCCESS
} from '../actions/medicialGroupActions'

export default (state = {status: "idle"}, { type, data = {} }) => {
  switch (type) {
    case GET_ORGANIZATION_DOMAIN_LIST_SUCCESS: 
      return {
        status: "success",
        data
      }
    case GET_ORGANIZATION_DOMAIN_LIST_FAILURE:
      return {
        status: "failed"
      }
    default:
      return state
  }
}
