import {
  MEDICAL_GROUP_ACCEPT_INVITATION_REQUEST_ENDPOINT,
  MEDICAL_GROUP_ACCESS_REQUEST_ENDPOINT,
  MEDICAL_GROUP_INVITATION_ENDPOINT,
  MEDICAL_GROUP_INVITATION_DOMAIN_ENDPOINT,
  STUDY_DEMO_REQUEST_ENDPOINT,
  PUT_SIGN_UP_ACCEPT_REJECT
} from '../config/api-endpoints'
import { authorizeHeader } from '../utils/auth-header'

export const getInvitionsListAPICall = async (emailID) => {
  try {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const response = await window.fetch(MEDICAL_GROUP_INVITATION_ENDPOINT + emailID + "/", authorizeHeader(config))

    const responseStatus = await response.status
    const responseOk = responseStatus === 200 || responseStatus === 201
    let responseJSON;
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      responseJSON = await response.json()
    } else {
      throw Error(`Response is not a JSON.`);
    }
    return { responseJSON, responseOk }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const getOrganizationDomainListAPICall = async (emailID) => {
  try {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const response = await window.fetch(MEDICAL_GROUP_INVITATION_DOMAIN_ENDPOINT + emailID + "/", authorizeHeader(config))
    const responseStatus = await response.status
    const responseOk = responseStatus === 200 || responseStatus === 201
    let responseJSON;
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      responseJSON = await response.json()
    } else {
      throw Error(`Response is not a JSON.`);
    }
    return { responseJSON, responseOk }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}


export const postDemoStudyAPICall = async (guid, facilityGuid) => {
  try {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        facility_guid: facilityGuid,
        modalities: ["CR", "MR"]
      })
    }

    const response = await window.fetch(STUDY_DEMO_REQUEST_ENDPOINT + guid + "/", authorizeHeader(config))
    const responseOk = await response.status
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const postAccessRequestAPICall = async ({ organization_name, organization_guid, user_email, user_id }) => {
  try {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        organization_guid,
        organization_name,
        user_email,
        user_id
      })
    }
    const response = await window.fetch(MEDICAL_GROUP_ACCESS_REQUEST_ENDPOINT + organization_guid + "/access-request/", authorizeHeader(config))
    const responseStatus = await response.status
    const responseOk = responseStatus === 200 || responseStatus === 201
    let responseJSON;
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      responseJSON = await response.json()
    } else{
      throw Error(`Response is not a JSON.`);
    }
    return { responseJSON, responseOk }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const putAccessRequestAPICall = async (payload) => {
  try {
    const config = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    let accept_or_reject = payload?.accept_or_reject ? "/reject/" : "/accept/"
    const response = await window.fetch(PUT_SIGN_UP_ACCEPT_REJECT + "/invitations/" + payload?.request_guid + accept_or_reject, authorizeHeader(config))
    const responseStatus = await response.status
    const responseOk = responseStatus === 200 || responseStatus === 201
    let responseJSON;
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      responseJSON = await response.json()
    } else {
      throw Error(`Response is not a JSON.`);
    }
    return { responseJSON, responseOk }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const postAcceptRequestAPICall = async (invitation_guid) => {
  try {
    const config = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const response = await window.fetch(MEDICAL_GROUP_ACCEPT_INVITATION_REQUEST_ENDPOINT + invitation_guid + "/accept/", authorizeHeader(config))
    const responseStatus = await response.status
    const responseOk = responseStatus === 200 || responseStatus === 201
    let responseJSON;
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      responseJSON = await response.json()
    } else{
      throw Error(`Response is not a JSON.`);
    }
    return { responseJSON, responseOk }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}
