import { call, put, takeLatest, takeEvery, all } from 'redux-saga/effects'
import store from 'store'
import history from '../routers/history'
import {
  POST_CREATE_FACILITY_REQUEST,
  POST_CREATE_FACILITY_FAILURE,
  postCreateFacilitySuccess,
  GET_INTERNAL_SOURCE_FACILITIES_REQUEST,
  GET_INTERNAL_SOURCE_FACILITIES_FAILURE,
  getInternalSourceFacilitiesSuccess
  // POST_CHECK_FACILITY_REQUEST,
  // POST_CHECK_FACILITY_FAILURE,
  // postCheckFacilitySuccess
} from '../actions/facilityActions'
import { postCreateFacilityAPICall, postUpdateFacilityAPICall, getInternalSourceFacilitiesCall } from '../apis/facility'
import { patchExistingUserAPICall } from '../apis/user'
import { postNotificationToSlackAPICall, postNotificationToZapierAPICall } from '../apis/slack'
import {
  clearNotification, dataLoadingNotification, checkAuthorizationError
  // errorNotification, successNotification
} from '../utils/notification-helpers'
import { USER_FORM_STEPS, SIGN_UP_STEPS } from '../config/form-settings'
import AuthService from '../services/AuthService'
import { LOCAL_STORAGE_APPROVED_FACILITIES, LOCAL_STORAGE_NEW_FACILITY_KEY, LOCAL_STORAGE_NEW_ORGANIZATION_KEY } from '../config/constants'
import { getAzureAddressApiCall } from '../apis/options'
import { t } from "../i18n";
import { postDemoStudyAPICall } from '../apis/medicialGroup'

// function * postCheckFacilityHandler ({ payload }) {
//   try {
//     const { facilityName } = payload
//     dataLoadingNotification('Looking up facility ' + facilityName + ' in our directory')
//     const { responseOk } = yield call(postCheckFacilityAPICall, payload)
//     clearNotification()
//     if (responseOk) {
//       // successNotification('Facility exists in our directory')
//       yield put(postCheckFacilitySuccess(true))
//       history.replace('/register/organization/' + USER_FORM_STEPS.STEP_06_A_REQUEST_USER_FACILITY_ACCESS)
//     } else {
//       // errorNotification('New facility added')
//       yield put(postCheckFacilitySuccess(false))
//       history.replace('/register/invite/' + USER_FORM_STEPS.STEP_07_A_COLLEAGUE)
//     }
//   } catch (e) {
//     yield put({
//       type: POST_CHECK_FACILITY_FAILURE,
//       message: e.message
//     })
//   }
// }

function * postCreateFacilityHandler ({ payload }) {
  try {
    const { data, callback } = payload
    const userMemberOf = store.get(LOCAL_STORAGE_NEW_ORGANIZATION_KEY)
    const auth = new AuthService()
    const userProfile = auth.getUserProfile()
    const userId = userProfile && userProfile.sub
    let storedApprovedFacilities = store.get(LOCAL_STORAGE_APPROVED_FACILITIES)
    if(storedApprovedFacilities===undefined){
      store.set(LOCAL_STORAGE_APPROVED_FACILITIES, [])
      storedApprovedFacilities = []
    }
    let updatedAppMetadata = {
      app_metadata: {
        affiliated_group: userMemberOf,
        admin: '1',
        main: '1',
        approved_facilities: storedApprovedFacilities,
        associated_groups: []
      }
    }
    dataLoadingNotification(t("facility_saga.create_new_facility"))

    let hasLogo = false
    const {userFacilityAddressLine1, userFacilityAddressLine2, userFacilityCity, userFacilityCountry, userFacilityEmail, userFacilityName, userFacilityPhone, userFacilityType, userFacilityLogo} = data

    if(userFacilityLogo && userFacilityLogo[0]){
      hasLogo = true
    }
    const requestData = {userFacilityCountry, userFacilityEmail, userFacilityName, userFacilityPhone, userFacilityType, userFacilityAddress: `${userFacilityAddressLine1} ${userFacilityAddressLine2 || ''}`, userFacilityCity, userFacilityLogo, userMemberOf}


    let containerURL
    if(hasLogo){
      containerURL = yield call (getAzureAddressApiCall, 'facilitylogos')
    }

    const currentFacilityGuid = store.get(LOCAL_STORAGE_NEW_FACILITY_KEY)
    let facilityCreateResponse;
    if(currentFacilityGuid){
      store.remove(LOCAL_STORAGE_NEW_FACILITY_KEY)
      facilityCreateResponse = yield call(postUpdateFacilityAPICall, {...requestData, guid: currentFacilityGuid, containerURL})
    } else{
      facilityCreateResponse = yield call(postCreateFacilityAPICall, {...requestData, containerURL})
    }


    const { responseOk, responseJSON } = facilityCreateResponse

    clearNotification()
    if (responseOk) {
      const  newStudy = yield call(postDemoStudyAPICall(userId, responseJSON?.guid))
      postNotificationToSlackAPICall({ title: '[Create Facility]', description: 'Successfully created new Facility', jsonContent: responseJSON })
      storedApprovedFacilities.push(responseJSON && responseJSON.guid)
      store.set(LOCAL_STORAGE_APPROVED_FACILITIES, storedApprovedFacilities)
      yield put(postCreateFacilitySuccess(responseJSON))
    } else {
      postNotificationToSlackAPICall({ title: '[Create Facility]', description: '`Failed to create new Facility`', jsonContent: responseJSON })
    }

    dataLoadingNotification(t("facility_saga.linking_user_facility"))
    const { responseOk: userUpdateOk, responseJSON: userResponseJSON } = yield call(patchExistingUserAPICall, userId, { appMetadata: updatedAppMetadata })
    clearNotification()
    if (userUpdateOk) {
      // proceed to Facility page
      postNotificationToSlackAPICall({ title: '[Update User app_metadata]', description: 'User APP METADATA updated successfully', jsonContent: userResponseJSON })
      const facilityData = {}
      let count =  store.get('facility_count')
      if(count === undefined){
        count = 1;
      } else{
        count+=1
      }
      store.set('facility_count', count)
      facilityData[`facility_${count}`] = responseJSON
      postNotificationToZapierAPICall({ jsonContent: {...userResponseJSON, ...facilityData } })
      if(callback){
        callback()
      } else{
        history.replace(`/signup/${SIGN_UP_STEPS['add-another-facility'].path}`)
      }
    } else {
      postNotificationToSlackAPICall({ title: '[Update User app_metadata]', description: '`Failed to update User APP METADATA`', jsonContent: userResponseJSON })
    }
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: POST_CREATE_FACILITY_FAILURE,
      message: e.message
    })
  }
}

function * getInternalFacilitiesHandler () {
  try {
    // const data = yield call(getConfigurationCall)
    const { responseStatus, responseJSON } = yield call(getInternalSourceFacilitiesCall)
    if(responseStatus >= 200 && responseStatus < 300){
      const facilities = responseJSON && responseJSON.facilities
      yield put(getInternalSourceFacilitiesSuccess(facilities))
    }
    else if(responseStatus === 401){
      throw Error(responseStatus);
    }
  } catch (e) {
    console.error(e)
    yield put({
      type: GET_INTERNAL_SOURCE_FACILITIES_FAILURE,
      message: e.message
    })
  }
}
export function * facilitySaga () {
  // yield takeLatest(POST_CHECK_FACILITY_REQUEST, postCheckFacilityHandler)
  yield takeLatest(POST_CREATE_FACILITY_REQUEST, postCreateFacilityHandler)
  yield takeLatest(GET_INTERNAL_SOURCE_FACILITIES_REQUEST, getInternalFacilitiesHandler)
}
