import { STOP_ALL_LOADING } from '../actions/loadingActions'

export default (state = {}, action) => {
  const { type } = action
  switch (type) {
    case STOP_ALL_LOADING:
      const loadings = {}
      Object.keys(state).forEach((requestName)=> loadings[requestName]=false)
      return loadings
    default:
      const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type)
      // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
      if (!matches) return state
      const [ , requestName, requestState ] = matches
      return {
        ...state,
        [requestName]: requestState === 'REQUEST'
      }
  }
}
