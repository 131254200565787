import React, { Component, /* lazy, */ Suspense } from 'react'
import { Router } from 'react-router-dom'
import history from './history'
import LoadingSpinner from '../components/partials/LoadingSpinner'
import DesktopRoutesContainer from './DesktopRoutesContainer'
import '../styles.desktop.css'

// const DesktopHeaderBar = lazy(() => import('../components/partials/DesktopHeaderBar'))

class DesktopPageLayout extends Component {
  render () {
    return (
      <Router basename='/' history={history}>
        <div className='container-fluid' style={{
          width: '100%',
          // paddingRight: '15px',
          // paddingLeft: '15px',
          marginRight: 'auto',
          marginLeft: 'auto'
        }}>
          <Suspense fallback={<LoadingSpinner />}>
            {/* <DesktopHeaderBar /> */}
            <DesktopRoutesContainer />
          </Suspense>
        </div>
      </Router>
    )
  }
}

export default DesktopPageLayout
