import { call, put, takeLatest } from 'redux-saga/effects'
import store from 'store'
import {
  POST_INVITE_REQUEST,
  POST_INVITE_FAILURE,
  POST_INVITE_COLLEAGUE_REQUEST,
  POST_INVITE_COLLEAGUE_FAILURE,
  POST_SOURCE_ORGANIZATIONS_REQUEST,
  POST_SOURCE_ORGANIZATIONS_FAILURE
} from '../actions/inviteActions'
import { postInviteColleagueAPICall, postWelcomeEmailAPICall } from '../apis/invite'
import { patchExistingUserAPICall, postCreateUserAPICall } from '../apis/user'
import { postInviteSignUpToZapierAPICall, postNotificationToSlackAPICall} from '../apis/slack'
import {
  clearNotification, dataLoadingNotification,
  errorNotification, successNotification
} from '../utils/notification-helpers'
import history from '../routers/history'
import { SIGN_UP_STEPS } from '../config/form-settings'
import AuthService from '../services/AuthService'
import { INVITE_ORG, LOCAL_STORAGE_NEW_ORGANIZATION_KEY } from '../config/constants'
import { t } from "../i18n";

function * postInviteColleaguHandler ({ payload }) {
  try {

    const { data } = payload
    const userMemberOf = store.get(LOCAL_STORAGE_NEW_ORGANIZATION_KEY)

    let updatedColleague = {
      name: data.name,
      email: data.email,
      app_metadata: {
        affiliated_group: userMemberOf,
        admin: '0',
        main: '0',
        approved_facilities: [],
        associated_groups: [userMemberOf]
      },
      user_metadata:{
        name: data.name,
        user_type: data.userType,
        sub_speciality: '',
        title:'',
        home_facility:'',
        credentials:''
      }
    }

    const response =  yield call(postCreateUserAPICall, updatedColleague)
    if(response.responseOk){
      clearNotification()
      successNotification(t("invite_saga.add_success"))
      const auth = new AuthService()
      yield call(auth.logout)
      history.replace(`/signup/${SIGN_UP_STEPS['success'].path}`)
    } else {
      errorNotification(t("invite_saga.add_failed"))
      postNotificationToSlackAPICall({ title: '[Invite Colleagues]', description: '`Failed to add colleagues`', jsonContent: payload })
      // history.replace('/register/user/' + USER_FORM_STEPS.STEP_08_D_PROCESS_FAILED)
      // history.replace(`/signup/${SIGN_UP_STEPS['failed'].path}`)
      throw new Error("Failed to add colleagues")
    }
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: POST_INVITE_COLLEAGUE_FAILURE,
      message: e.message
    })
  }
}

function * postInviteHandler ({ payload }) {
  try {

    const auth = new AuthService()
    const profile = auth.getUserProfile()
    const { emailAddresses } = payload

    let emailsData = emailAddresses?.trim()
    let emails = [];

    if (emailsData?.split(",").length > 1) {
      emailsData = emailsData?.split(",")
    } else if (emailsData?.split(" ").length > 1) {
      emailsData = emailsData?.split(" ")
    } else if (emailsData?.split(/\r?\n/)) {
      emailsData = emailsData?.split(/\r?\n/)
    } else {
      emailsData = emailsData?.split(",")
    }

    emailsData.map(email => {
      if (email && email !== '') {
        if (email?.split(/\r?\n/).length > 1) {
          email.split(/\r?\n/).map(subEmail => {
            if (subEmail !== '') {
              emails = [...emails, subEmail.trim()]
            }
            return subEmail
          })
        } else {
          emails = [...emails, email.trim()]
        }
      }
      return email
    })

    const orgData = store.get(INVITE_ORG);

    const data = {
      organization_guid: orgData.groupId,
      organization_name: orgData.groupName,
      inviter_name: profile.name,
      invitee_emails: emails
    }

    const responseZapier = yield call(postInviteSignUpToZapierAPICall, {
      jsonContent: {
        inviter_name: profile.name,
        inviter_email: profile.email,
        organization_guid: orgData.groupId,
        organization_name: orgData.groupName,
        invitee_emails: emails  
      },
    });
    // console.log("responseZapier---------------", responseZapier);
    const response =  yield call(postInviteColleagueAPICall, data)
    if(response.responseOk){
      clearNotification()
      successNotification(t("invite_saga.invite_success"))
      const auth = new AuthService()
      yield call(auth.logout)
      history.replace(`/signup/${SIGN_UP_STEPS['success'].path}`)
      store.remove(INVITE_ORG);
    } else {
      errorNotification(t("invite_saga.invite_failed"))
      // history.replace('/register/user/' + USER_FORM_STEPS.STEP_08_D_PROCESS_FAILED)
      // history.replace(`/signup/${SIGN_UP_STEPS['failed'].path}`)
      throw new Error("Failed to send invitation")
    }
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: POST_INVITE_FAILURE,
      message: e.message
    })
  }
}

function * postSourceOrganizationsHandler ({ payload }) {
  try {
    dataLoadingNotification(t("invite_saga.loading_organization"))
    const { responseOk } = yield call(postNotificationToSlackAPICall, { title: '[Store facility user read for]', description: 'User usually ready for this Source Organizations', jsonContent: payload })
    clearNotification()
    if (responseOk) {
      successNotification(t("invite_saga.oraganization_success"))
    } else {
      errorNotification(t("invite_saga.organization_failed"))
      history.replace(`/signup/${SIGN_UP_STEPS['failed'].path}`)
    }
    const auth = new AuthService()
    const userProfile = auth.getUserProfile()
    const userId = userProfile && userProfile.sub
    let updatedAppMetadata = {
      app_metadata: {
        admin: '1',
        main: '1',
        approved_facilities: [],
        associated_groups: [],
        affiliated_group: ''
      }
    }
    dataLoadingNotification(t("invite_saga.update_user_information"))
    const { responseOk: userUpdateOk, responseJSON: userResponseJSON } = yield call(patchExistingUserAPICall, userId, { appMetadata: updatedAppMetadata })
    clearNotification()
    if (userUpdateOk) {
      postNotificationToSlackAPICall({ title: '[Update User app_metadata]', description: 'User APP METADATA updated successfully', jsonContent: userResponseJSON })
      const auth = new AuthService()
      const userProfile = auth.getUserProfile()
      yield call(postWelcomeEmailAPICall, userProfile)
      yield call(auth.logout)
      history.replace(`/signup/${SIGN_UP_STEPS['success'].path}`)
    } else {
      postNotificationToSlackAPICall({ title: '[Update User app_metadata]', description: '`Failed to update User APP METADATA`', jsonContent: userResponseJSON })
      history.replace(`/signup/${SIGN_UP_STEPS['failed'].path}`)
    }
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: POST_SOURCE_ORGANIZATIONS_FAILURE,
      message: e.message
    })
  }
}

export function * studyDetailSaga () {
  yield takeLatest(POST_INVITE_COLLEAGUE_REQUEST, postInviteColleaguHandler)
  yield takeLatest(POST_SOURCE_ORGANIZATIONS_REQUEST, postSourceOrganizationsHandler)
  yield takeLatest(POST_INVITE_REQUEST, postInviteHandler)  
}
