export const GET_CHECK_GROUP = 'GET_CHECK_GROUP'
export const GET_CHECK_GROUP_REQUEST = 'GET_CHECK_GROUP_REQUEST'
export const GET_CHECK_GROUP_SUCCESS = 'GET_CHECK_GROUP_SUCCESS'
export const GET_CHECK_GROUP_FAILURE = 'GET_CHECK_GROUP_FAILURE'

export const getCheckGroupRequest = (payload) => ({ type: GET_CHECK_GROUP_REQUEST, payload })
export const getCheckGroupSuccess = (data) => {
  return ({ type: GET_CHECK_GROUP_SUCCESS, data })
}

export const GET_GROUP = 'GET_GROUP'
export const GET_GROUP_REQUEST = 'GET_GROUP_REQUEST'
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS'
export const GET_GROUP_FAILURE = 'GET_GROUP_FAILURE'

export const getGroupRequest = () => ({ type: GET_GROUP_REQUEST })
export const getGroupSuccess = (data) => {
  return ({ type: GET_GROUP_SUCCESS, data })
}

export const POST_CREATE_GROUP = 'POST_CREATE_GROUP'
export const POST_CREATE_GROUP_REQUEST = 'POST_CREATE_GROUP_REQUEST'
export const POST_CREATE_GROUP_SUCCESS = 'POST_CREATE_GROUP_SUCCESS'
export const POST_CREATE_GROUP_FAILURE = 'POST_CREATE_GROUP_FAILURE'

export const postCreateGroupRequest = (payload) => ({ type: POST_CREATE_GROUP_REQUEST, payload })
export const postCreateGroupSuccess = (data) => {
  return ({ type: POST_CREATE_GROUP_SUCCESS, data })
}

export const POST_ASSIGN_GROUP = 'POST_ASSIGN_GROUP'
export const POST_ASSIGN_GROUP_REQUEST = 'POST_ASSIGN_GROUP_REQUEST'
export const POST_ASSIGN_GROUP_SUCCESS = 'POST_ASSIGN_GROUP_SUCCESS'
export const POST_ASSIGN_GROUP_FAILURE = 'POST_ASSIGN_GROUP_FAILURE'

export const postAssignGroupRequest = (payload) => ({ type: POST_ASSIGN_GROUP_REQUEST, payload })
export const postAssignGroupSuccess = (data) => {
  return ({ type: POST_ASSIGN_GROUP_SUCCESS, data })
}

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';

export const updateGroupRequest = (payload) => ({ type: UPDATE_GROUP_REQUEST, payload });
