import React, { Component, /* lazy, */ Suspense } from 'react'
import { Router } from 'react-router-dom'
import history from './history'
import LoadingSpinner from '../components/partials/LoadingSpinner'
import MobileRoutesContainer from './MobileRoutesContainer'
import '../styles.mobile.css'

// const MobileDesktopHeaderBar = lazy(() => import('../components/partials/MobileHeaderBar'))

class MobilePageLayout extends Component {
  render () {
    return (
      <Router basename='/' history={history}>
        <div className='container-fluid' style={{ width: '100%' }}>
          <Suspense fallback={<LoadingSpinner />}>
            {/* <MobileDesktopHeaderBar /> */}
            <div style={{ width: '100%' }}>
              <MobileRoutesContainer />
            </div>
          </Suspense>
        </div>
      </Router>
    )
  }
}

export default MobilePageLayout
