import React, { Component, lazy } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import LoadingSpinner from '../components/partials/LoadingSpinner'
import AuthService from '../services/AuthService'

// const MobileHomeComponent = lazy(() => import('../components/MobileHomeComponent'))
const MobileHomeComponent = lazy(() => import('../components/Home'))
const QrScannedComponent = lazy(() => import('../components/QrScannedComponent'))
// const MobileUserContainer = lazy(() => import('../containers/MobileUserContainer'))
// const MobileFacilityContainer = lazy(() => import('../containers/MobileFacilityContainer'))
// const MobileInviteContainer = lazy(() => import('../containers/MobileInviteContainer'))
const MobileSignUpContainer = lazy(() => import('../containers/MobileSignUpContainer'))
const QrPatientStudy = lazy(() => import('../components/QrPatientStudy'))
const QrMedicalProfessional = lazy(() => import('../components/QrMedicalProfessional'))

const auth = new AuthService()

const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication()
  }
}

const PublicRoute = ({ component: Component, ...rest }) => {
  // if (isProduction) {
  //   analytics.load(segmentIOWriteKey)
  //   analytics.page(rest.path, { path: rest.path })
  // }
  return (
    <Route {...rest} render={(props) => (<Component {...props} />)} />
  )
}

class RoutesContainer extends Component {
  render () {
    return (
      <Switch>
        <Route exact path='/auth0-callback' render={(props) => {
          handleAuthentication(props)
          return <LoadingSpinner {...props} />
        }} />
        <PublicRoute exact path='/' component={MobileHomeComponent} />
        <Route exact path='/signup/' render={(props) => {
          return <Redirect to="/signup/user-type" />
        }} />
        <PublicRoute exact path='/signup/:currentPage' component={MobileSignUpContainer} />
        <PublicRoute exact path='/qr-scanned' component={QrScannedComponent} />
        <PublicRoute exact path='/qr-scanned-patient' component={QrPatientStudy} />
        <PublicRoute exact path='/qr-scanned-medical-professional' component={QrMedicalProfessional} />
        <PublicRoute exact path='/share-link' component={QrMedicalProfessional} />
        {/* <PublicRoute exact path='/register/user' component={MobileUserContainer} />
        <PublicRoute exact path='/register/user/:stepNumber' component={MobileUserContainer} />
        <PublicRoute exact path='/register/organization/:stepNumber' component={MobileFacilityContainer} />
        <PublicRoute exact path='/register/invite/:stepNumber' component={MobileInviteContainer} /> */}
      </Switch>
    )
  }
}

export default withRouter(RoutesContainer)
