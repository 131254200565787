import React, { Component } from 'react'
import { Spin } from 'antd'

const fullViewStyle = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
  top: '50vh',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: '1011'
}
const smallViewStyle = {
  display: 'flex',
  justifyContent: 'center',
  position: 'center',
  width: '64px',
  margin: '0 auto',
  zIndex: '1011'
}
const tinyViewStyle = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  height: '25px',
  width: '25px',
  top: '12px',
  bottom: 0,
  right: '8px',
  zIndex: '1011'
}

class LoadingSpinner extends Component {
  render () {
    const { size = 'full' } = this.props
    let style
    switch (size) {
      case 'small':
        style = smallViewStyle
        break
      case 'tiny':
        style = tinyViewStyle
        break
      default:
        style = fullViewStyle
    }
    return (
      <div style={style}>
        {<Spin size='large' />}
      </div>
    )
  }
}

export default LoadingSpinner
