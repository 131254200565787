export const isMobile = {
    Android: () => {
      return window.navigator.userAgent.match(/Android/i)
    },
    BlackBerry: () => {
      return window.navigator.userAgent.match(/BlackBerry/i)
    },
    iOS: () => {
      return window.navigator.userAgent.match(/iPhone|iPad|iPod/i)
    },
    Opera: () => {
      return window.navigator.userAgent.match(/Opera Mini/i)
    },
    Windows: () => {
      return window.navigator.userAgent.match(/IEMobile/i)
    },
    any: () => {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows())
    }
  }
