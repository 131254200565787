export const GET_MEDICAL_GROUPS_INVITATIONS_LIST = 'GET_MEDICAL_GROUPS_INVITATIONS_LIST'
export const GET_MEDICAL_GROUPS_INVITATIONS_LIST_REQUEST = 'GET_MEDICAL_GROUPS_INVITATIONS_LIST_REQUEST'
export const GET_MEDICAL_GROUPS_INVITATIONS_LIST_SUCCESS = 'GET_MEDICAL_GROUPS_INVITATIONS_LIST_SUCCESS'
export const GET_MEDICAL_GROUPS_INVITATIONS_LIST_FAILURE = 'GET_MEDICAL_GROUPS_INVITATIONS_LIST_FAILURE'

export const getInvitationsListRequest = () => ({ type: GET_MEDICAL_GROUPS_INVITATIONS_LIST_REQUEST })
export const getInvitationsListSuccess = (data) => ({ type: GET_MEDICAL_GROUPS_INVITATIONS_LIST_SUCCESS, data })
export const getInvitationsListFailure = (error) => ({ type: GET_MEDICAL_GROUPS_INVITATIONS_LIST_FAILURE, payload: error })

export const POST_MEDICAL_GROUP_INVITATION = 'POST_MEDICAL_GROUP_INVITATION'
export const POST_MEDICAL_GROUP_INVITATION_REQUEST = 'POST_MEDICAL_GROUP_INVITATION_REQUEST'
export const POST_MEDICAL_GROUP_INVITATION_SUCCESS = 'POST_MEDICAL_GROUP_INVITATION_SUCCESS'
export const POST_MEDICAL_GROUP_INVITATION_FAILURE = 'POST_MEDICAL_GROUP_INVITATION_FAILURE'

export const postMedicalGroupInvitationRequest = (organizationGuid) => ({ type: POST_MEDICAL_GROUP_INVITATION_REQUEST, payload: { organizationGuid } })
export const postMedicalGroupInvitationSuccess = (data) => ({ type: POST_MEDICAL_GROUP_INVITATION_SUCCESS, data })
export const postMedicalGroupInvitationFailure = (error) => ({ type: POST_MEDICAL_GROUP_INVITATION_FAILURE, payload: error })

export const GET_ORGANIZATION_DOMAIN_LIST = 'GET_ORGANIZATION_DOMAIN_LIST'
export const GET_ORGANIZATION_DOMAIN_LIST_REQUEST = 'GET_ORGANIZATION_DOMAIN_LIST_REQUEST'
export const GET_ORGANIZATION_DOMAIN_LIST_SUCCESS = 'GET_ORGANIZATION_DOMAIN_LIST_SUCCESS'
export const GET_ORGANIZATION_DOMAIN_LIST_FAILURE = 'GET_ORGANIZATION_DOMAIN_LIST_FAILURE'

export const getOrganizationDomainListRequest = () => ({ type: GET_ORGANIZATION_DOMAIN_LIST_REQUEST })
export const getOrganizationDomainListSuccess = (data) => ({ type: GET_ORGANIZATION_DOMAIN_LIST_SUCCESS, data })
export const getOrganizationDomainListFailure = (error) => ({ type: GET_ORGANIZATION_DOMAIN_LIST_FAILURE, payload: error })

export const POST_ACCESS_REQUEST_MEDICAL_GROUP = 'POST_ACCESS_REQUEST_MEDICAL_GROUP'
export const POST_ACCESS_REQUEST_MEDICAL_GROUP_REQUEST = 'POST_ACCESS_REQUEST_MEDICAL_GROUP_REQUEST'
export const POST_ACCESS_REQUEST_MEDICAL_GROUP_SUCCESS = 'POST_ACCESS_REQUEST_MEDICAL_GROUP_SUCCESS'
export const POST_ACCESS_REQUEST_MEDICAL_GROUP_FAILURE = 'POST_ACCESS_REQUEST_MEDICAL_GROUP_FAILURE'

export const postAccessRequestRequest = (payload) => ({ type: POST_ACCESS_REQUEST_MEDICAL_GROUP_REQUEST, payload })
export const postAccessRequestSuccess = (data) => ({ type: POST_ACCESS_REQUEST_MEDICAL_GROUP_SUCCESS, data })
export const postAccessRequestFailure = (error) => ({ type: POST_ACCESS_REQUEST_MEDICAL_GROUP_FAILURE, payload: error })

export const POST_ACCEPT_REQUEST_MEDICAL_GROUP = 'POST_ACCEPT_REQUEST_MEDICAL_GROUP'
export const POST_ACCEPT_REQUEST_MEDICAL_GROUP_REQUEST = 'POST_ACCEPT_REQUEST_MEDICAL_GROUP_REQUEST'
export const POST_ACCEPT_REQUEST_MEDICAL_GROUP_SUCCESS = 'POST_ACCEPT_REQUEST_MEDICAL_GROUP_SUCCESS'
export const POST_ACCEPT_REQUEST_MEDICAL_GROUP_FAILURE = 'POST_ACCEPT_REQUEST_MEDICAL_GROUP_FAILURE'

export const acceptRequestRequest = (payload) => ({ type: POST_ACCEPT_REQUEST_MEDICAL_GROUP_REQUEST, payload })
export const acceptRequestSuccess = (data) => ({ type: POST_ACCEPT_REQUEST_MEDICAL_GROUP_SUCCESS, data })
export const acceptRequestFailure = (error) => ({ type: POST_ACCEPT_REQUEST_MEDICAL_GROUP_FAILURE, payload: error })


export const PUT_ACCESS_REQUEST_MEDICAL_GROUP = 'PUT_ACCESS_REQUEST_MEDICAL_GROUP'
export const PUT_ACCESS_REQUEST_MEDICAL_GROUP_REQUEST = 'PUT_ACCESS_REQUEST_MEDICAL_GROUP_REQUEST'
export const PUT_ACCESS_REQUEST_MEDICAL_GROUP_SUCCESS = 'PUT_ACCESS_REQUEST_MEDICAL_GROUP_SUCCESS'
export const PUT_ACCESS_REQUEST_MEDICAL_GROUP_FAILURE = 'PUT_ACCESS_REQUEST_MEDICAL_GROUP_FAILURE'

export const putAccessRequestRequest = (payload) => ({ type: PUT_ACCESS_REQUEST_MEDICAL_GROUP_REQUEST, payload })


export const PUT_REJECT_MEDICAL_GROUP_REQUEST = 'PUT_REJECT_MEDICAL_GROUP_REQUEST'

export const putRejectAccessRequest = (payload) => ({ type: PUT_REJECT_MEDICAL_GROUP_REQUEST, payload })
