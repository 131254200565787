import { call, put, takeLatest } from 'redux-saga/effects'
import store from 'store'
import { PUT_STUDY_SHARE_FAILURE, PUT_STUDY_SHARE_REQUEST, PUT_STUDY_SHARE_SUCCESS, STUDY_ASSIGNMENT_FAILURE, STUDY_ASSIGNMENT_REQUEST } from '../actions/studyActions'
import { postStudyShareAPICall, putAssignStudyAPICall } from '../apis/study'
import { LOCAL_STORAGE_STUDY_ID, validUuidRegularExpression } from '../config/constants'
import AuthService from '../services/AuthService'
import { checkAuthorizationError, clearNotification, dataLoadingNotification, errorNotification, successNotification } from '../utils/notification-helpers'

function * assignStudyHandler ({ payload }) {
  try {
    if (validUuidRegularExpression.test(payload)) {
      const auth = new AuthService()
      const userProfile = auth.getUserProfile()
      const userId = userProfile && userProfile.sub

      const { responseOk: assignmentResponseOk } = yield call(putAssignStudyAPICall, payload, userId)
      if (assignmentResponseOk) {
        store.remove(LOCAL_STORAGE_STUDY_ID)
        successNotification("Study is Assigned");
      } else {
        errorNotification("Assignment Guid is invalid");
      }
    } else {
      errorNotification("Assignment Guid is invalid");
    }
    
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: STUDY_ASSIGNMENT_FAILURE,
      message: e.message
    })
  }
}

function * postStudyShareHandler ({ payload }) {
  try {
    // const data = yield call(putStudyDetailAPICall, payload)
    dataLoadingNotification(`Sharing study`)

    const {responseStatus} = yield call(postStudyShareAPICall, { guid: payload.guid })

    clearNotification()
    if(responseStatus >= 200 && responseStatus < 300){
      // store.remove(LOCAL_STORAGE_STUDY_ID)
      successNotification('Study successfully shared')
      yield put({
        type: PUT_STUDY_SHARE_SUCCESS
      })
    } else if(responseStatus === 401){
      throw Error(responseStatus);
    } else {
      throw new Error('Sharing study failed')
    }
  } catch (e) {
    clearNotification()
    successNotification('Sharing study failed')
    console.error(e)
    yield put({
      type: PUT_STUDY_SHARE_FAILURE,
      message: e.message
    })
    checkAuthorizationError(e.message)
  }
}

export function * studySaga () {
  yield takeLatest(STUDY_ASSIGNMENT_REQUEST, assignStudyHandler)
  yield takeLatest(PUT_STUDY_SHARE_REQUEST, postStudyShareHandler)
}
