export const ENVIRONMENT = process.env.REACT_APP_ENV
export const LOCAL_STORAGE_PREFIX = 'ah-signup-'
export const LOCAL_STORAGE_PERSISTENT_STATE_KEY = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-state'
export const LOCAL_STORAGE_COUNTRY_LIST_KEY = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-country-list'
export const LOCAL_STORAGE_NEW_ORGANIZATION_KEY = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-organization-guid'
export const LOCAL_STORAGE_NEW_FACILITY_KEY = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-facility-guid'
export const LOCAL_STORAGE_APPROVED_FACILITIES = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-approved-facilities'
export const LOCAL_STORAGE_USER_TYPE = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-user-type'
export const LOCAL_STORAGE_SESSION_TAG_KEY = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-session-tag'
export const LOCAL_STORAGE_IP_API_TRACKER_KEY = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-ip-api-tracker'
export const LOCAL_STORAGE_STUDY_ID = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-study-id' // study share id
export const LOCAL_STORAGE_GROUP_ID = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-group-id'
export const LOCAL_STORAGE_STUDY_GUID = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-study-guid'
export const LOCAL_STORAGE_COMMUNITY_PAGE = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-community-page'
export const LOCAL_STORAGE_DYNAMIC_REDIRECT_PAGE = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-dynamic-redirect-page'
export const LOCAL_STORAGE_SOURCE_PATH = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-source-path'

export const LOCAL_STORAGE_AUTH_KEY = {
  USER_PROFILE: LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-auth-user_profile',
  ACCESS_TOKEN: LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-auth-access_token',
  STATE: LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-auth-state',
  USER_PASS: LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-auth-user_password',
  ID_TOKEN: LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-auth-id_token',
  EXPIRES_AT: LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-auth-expires_at',
  POST_LOGIN_TARGET: LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-post-login-target',
  MGMT_ACCESS_TOKEN: LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-auth-mgmt-access_token',
  MGMT_ID_TOKEN: LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-auth-mgmt-id_token'
}

export const ALEM_HEALTH_EMAIL_SUPPORT = 'mailto:support@alemhealth.com?Subject=Connect%20Signup%20Support%20Request:%20'

export const STUDY_DATE_TIME_FORMATS = {
  upload: {
    patientDob: 'YYYY-MM-DD',
    studyDate: 'YYYY-MM-DD',
    studyTime: 'hh:mm:ss'
  },
  patient: {
    dob: 'DD-MM-YYYY'
  },
  date: 'DD MMM YYYY',
  studyList: 'DD MMM YYYY [[]HH:mm[]]',
  workList: 'DD MMM YYYY, hh:mm A',
  time: 'H:mm A',
  questionnaire: {
    lastMenstrualDate: 'DD / MM / YYYY',
    lastMammogramDate: 'DD / MM / YYYY'
  },
  createdAt: 'HH[H] mm[M] s[S]'
}

export const CONNECT_APP_URL = process.env.REACT_APP_AH_CONNECT_APP_URL
export const ADMIN_APP_URL = 'https://admin.alem.health'
export const Patients_APP_URL = 'https://patients.alem.health/login'

export const Term_Service_URL = "https://www.alemhealth.com/termsofservice" 
export const Privacy_Policy_URL = "https://www.alemhealth.com/privacy-policy" 

export const validEmailRegularExpression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i
export const validUuidRegularExpression = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
// export const validEmailRegularExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const phoneNumberFields = [ 'userPhone', 'userGroupPhone', 'userFacilityPhone', 'phone' ]

export const shareLinkPathList = ['share-link', 'qr-scanned-medical-professional']

export const INVITE_ORG = LOCAL_STORAGE_PREFIX + ENVIRONMENT + '-invite-orgnization'
export const allowSlackNotifications = ENVIRONMENT !== 'local'
export const SLACK_APP_NAME = 'Connect Signup App'
export const SLACK_APP_TYPE = process.env.REACT_APP_SLACK_MODE
export const SLACK_WEB_HOOK = 'https://hooks.slack.com/services/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'
export const SLACK_WEB_HOOK_DELETE_GROUP = 'https://hooks.zapier.com/hooks/catch/599828/bjzy8if/'
export const ZAPIER_WEB_HOOK = 'https://hooks.zapier.com/hooks/catch/599828/omjjbte/'
export const ZAPIER_INVITE_SIGN_UP_WEB_HOOK = 'https://hooks.zapier.com/hooks/catch/599828/ba95rre/'
export const ZAPIER_PARTNER_PROGRAM_WEB_HOOK = "https://hooks.zapier.com/hooks/catch/599828/3dw0hta/"
export const SLACK_WEB_HOOK_TELERADOPS = 'https://hooks.slack.com/services/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'
export const ZAPIER_REQUEST_JOIN_AFFILIATE_WEB_HOOK = 'https://hooks.zapier.com/hooks/catch/599828/bp2khck/'

export const IP_VERIFY_SERVICE = 'https://ipinfo.io/json' // 'https://api.ipdata.co'

export const WELCOME_MESSAGE_TEMPLATE_ID = 'WELCOME_NEW_USER'

export const userTypeAlemHealth = [
  'Radiologist',
  'Radiographer',
  'Physician',
  'patient',
  'Partner',
  'something_else',
];