import { PATCH_USER_ENDPOINT, PATCH_USER_PROFILE_ENDPOINT, USER_EXISTING_ENDPOINT, USER_NEW_ENDPOINT } from '../config/api-endpoints'
import { authorizeHeader } from '../utils/auth-header'
import AuthService from '../services/AuthService'

export const patchNewUserAPICall = async (userId, { user, appMetadata, userMetadata }) => {
  try {
    let payloadToPass = { ...user, ...appMetadata, ...userMetadata }
    const config = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payloadToPass)
    }
    const response = await window.fetch(USER_NEW_ENDPOINT + '/' + encodeURIComponent(userId), authorizeHeader(config))
    const responseOk = await response.status === 200
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const patchExistingUserAPICall = async (userId, { user, appMetadata, userMetadata }) => {
  try {
    let payloadToPass = { ...user, ...appMetadata, ...userMetadata }
    const config = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payloadToPass)
    }
    const response = await window.fetch(USER_EXISTING_ENDPOINT + '/' + encodeURIComponent(userId), authorizeHeader(config))
    const responseOk = await response.status === 200
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}


export const getNewAccessTokenUserAPICall = async (userId, { user, appMetadata, userMetadata }) => {
  try {
    let payloadToPass = { ...user, ...appMetadata, ...userMetadata }
    const config = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payloadToPass)
    }
    const response = await window.fetch(USER_EXISTING_ENDPOINT + '/' + encodeURIComponent(userId), authorizeHeader(config))
    const responseOk = await response.status === 200
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const patchUserProfileApiCall = async (userId, requestBody) => {
  try {
    const config = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    }
    const response = await window.fetch(PATCH_USER_PROFILE_ENDPOINT, authorizeHeader(config))
    const responseStatus = await response.status
    let responseJSON;
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      responseJSON = await response.json()
    } else {
      throw Error(`Response is not a JSON.`);
    }
    return { responseStatus, responseJSON }
  } catch (e) {
    console.error(e)
    return e
  }
}

export const postCreateUserAPICall = async (requestData) => {
  try {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    }
    const response = await window.fetch(USER_EXISTING_ENDPOINT, authorizeHeader(config))
    const responseOk = await response.status === 201
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const patchUserUpdateApiCall = async (userId, requestBody) => {
  try {
    const config = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    }
    const response = await window.fetch(PATCH_USER_ENDPOINT + userId, authorizeHeader(config))
    const responseStatus = await response.status
    let responseJSON;
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      responseJSON = await response.json()
    } else {
      throw Error(`Response is not a JSON.`);
    }
    return { responseStatus, responseJSON }
  } catch (e) {
    console.error(e)
    return e
  }
}


export const getUpdatedUserApiCall = async () => {
  const authService = new AuthService()
  const profile = authService.getUserProfile()
  try {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const response = await window.fetch(PATCH_USER_ENDPOINT + profile?.sub, authorizeHeader(config))
    const responseStatus = await response.status
    let responseJSON;
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      responseJSON = await response.json()
    } else {
      throw Error(`Response is not a JSON.`);
    }
    if (responseStatus === 200) {
      return responseJSON
    }
  } catch (e) {
    console.log(e)
    // return e
  }
}
// https://alemhealthapi.azure-api.net/teleradsetting/api/users/auth0|64a268dff8b779439161c5ab