import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// Translation
import translationEN from "./translations/en.json";
import translationES from "./translations/es.json";
import translationFR from "./translations/fr.json";

const fallbackLng = ["en"];
const availableLanguages = ["en", "es", "fr"];

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  }
};

const options = {
  order: ["localStorage", "navigator"],
  lookupLocalStorage: "i18nextLng",
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: options,

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

const t = i18n.t.bind(i18n);
export { t };
