export const STUDY_ASSIGNMENT = 'STUDY_ASSIGNMENT'
export const STUDY_ASSIGNMENT_REQUEST = 'STUDY_ASSIGNMENT_REQUEST'
export const STUDY_ASSIGNMENT_SUCCESS = 'STUDY_ASSIGNMENT_SUCCESS'
export const STUDY_ASSIGNMENT_FAILURE = 'STUDY_ASSIGNMENT_FAILURE'

export const studyAssignmentRequest = (payload) => {
  return ({ type: STUDY_ASSIGNMENT_REQUEST, payload })
}

export const PUT_STUDY_SHARE = 'PUT_STUDY_SHARE'
export const PUT_STUDY_SHARE_REQUEST = 'PUT_STUDY_SHARE_REQUEST'
export const PUT_STUDY_SHARE_SUCCESS = 'PUT_STUDY_SHARE_SUCCESS'
export const PUT_STUDY_SHARE_FAILURE = 'PUT_STUDY_SHARE_FAILURE'

export const putStudyShareRequest = ({ guid, name, email, sharedBy }) => {
  return ({ type: PUT_STUDY_SHARE_REQUEST, payload: { guid, name, email, sharedBy } })}
export const putStudyShareSuccess = (payload) => {
  return ({ type: PUT_STUDY_SHARE_SUCCESS, payload })
}
export const putStudyShareFailure = (payload) => {
  return ({ type: PUT_STUDY_SHARE_FAILURE, payload })
}
