import sjcl from 'sjcl';

export default (token, text) => {
  text = window.atob(text);
  const cyphertext = JSON.stringify({
    salt: text.substring(0, 12),
    iter: 10000,
    ks: 128,
    ct: text.substring(36),
    iv: text.substring(12, 36),
    cipher: 'aes',
    mode: 'ccm',
    adata: '',
    v: 1,
    ts: 64
  });
  return sjcl.decrypt(token, cyphertext);
};
