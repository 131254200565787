import store from 'store'
import { PRE_SIGNUP_SUBSCRIPTION_KEY, POST_SIGNUP_SUBSCRIPTION_KEY } from '../config/api-endpoints'
import { LOCAL_STORAGE_AUTH_KEY } from '../config/constants'
import history from '../routers/history'
import { errorNotification } from './notification-helpers'
import { SIGN_UP_STEPS } from '../config/form-settings'
import { t } from "../i18n";

export const authorizeHeader = (config, idTokenRequired = false, isAuth0Management = false) => {
  // const token = 'Bearer ' + store.get(LOCAL_STORAGE_AUTH_KEY.ACCESS_TOKEN) + ' ' + store.get(LOCAL_STORAGE_AUTH_KEY.ID_TOKEN)
  const accessToken = store.get((isAuth0Management) ? LOCAL_STORAGE_AUTH_KEY.MGMT_ACCESS_TOKEN : LOCAL_STORAGE_AUTH_KEY.ACCESS_TOKEN)
  const idToken = store.get((isAuth0Management) ? LOCAL_STORAGE_AUTH_KEY.MGMT_ID_TOKEN : LOCAL_STORAGE_AUTH_KEY.ID_TOKEN)
  try{
  if(!accessToken){
    throw new Error();
  }
  let token = 'Bearer ' + accessToken
  token += (idTokenRequired) ? (' ' + idToken) : ''
  if (config && config.headers) {
    config.headers['Authorization'] = token
    config.headers['Ocp-Apim-Subscription-Key'] = POST_SIGNUP_SUBSCRIPTION_KEY
  } else {
    config['headers'] = {
      'Authorization': 'JWT ' + token,
      'Ocp-Apim-Subscription-Key': POST_SIGNUP_SUBSCRIPTION_KEY
    }
  }
  return config
  }
  catch (e) {
    history.replace(`/signup/${SIGN_UP_STEPS['failed'].path}`)
    errorNotification(t("error_token"))
  }

}

export const configureHeaderForEmailVerification = (config) => {
  if (config && config.headers) {
    config.headers['Ocp-Apim-Subscription-Key'] = PRE_SIGNUP_SUBSCRIPTION_KEY
  } else {
    config['headers'] = {
      'Ocp-Apim-Subscription-Key': PRE_SIGNUP_SUBSCRIPTION_KEY
    }
  }
  return config
}
