import { call, put, takeLatest } from 'redux-saga/effects'
import store from 'store'
import {
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNTRY_LIST_FAILURE
} from '../actions/countryListActions'
import { getCountryListAPICall } from '../apis/country-list'
import { LOCAL_STORAGE_COUNTRY_LIST_KEY } from '../config/constants'

function * getCountryListHandler () {
  try {
    const { responseJSON } = yield call(getCountryListAPICall)
    yield store.set(LOCAL_STORAGE_COUNTRY_LIST_KEY, responseJSON)
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: GET_COUNTRY_LIST_FAILURE,
      message: e.message
    })
  }
}

export function * configurationSaga () {
  yield takeLatest(GET_COUNTRY_LIST_REQUEST, getCountryListHandler)
}
