export const POST_CHECK_FACILITY = 'POST_CHECK_FACILITY'
export const POST_CHECK_FACILITY_REQUEST = 'POST_CHECK_FACILITY_REQUEST'
export const POST_CHECK_FACILITY_SUCCESS = 'POST_CHECK_FACILITY_SUCCESS'
export const POST_CHECK_FACILITY_FAILURE = 'POST_CHECK_FACILITY_FAILURE'

export const postCheckFacilityRequest = (facilityName, facilityCountry) => ({ type: POST_CHECK_FACILITY_REQUEST, payload: { facilityName, facilityCountry } })
export const postCheckFacilitySuccess = (data) => {
  return ({ type: POST_CHECK_FACILITY_SUCCESS, data })
}

export const POST_CREATE_FACILITY = 'POST_CREATE_FACILITY'
export const POST_CREATE_FACILITY_REQUEST = 'POST_CREATE_FACILITY_REQUEST'
export const POST_CREATE_FACILITY_SUCCESS = 'POST_CREATE_FACILITY_SUCCESS'
export const POST_CREATE_FACILITY_FAILURE = 'POST_CREATE_FACILITY_FAILURE'

export const postCreateFacilityRequest = (data, callback) => ({ type: POST_CREATE_FACILITY_REQUEST, payload: { data, callback} })
export const postCreateFacilitySuccess = (data) => {
  return ({ type: POST_CREATE_FACILITY_SUCCESS, data })
}

export const GET_INTERNAL_SOURCE_FACILITIES = 'GET_INTERNAL_SOURCE_FACILITIES'
export const GET_INTERNAL_SOURCE_FACILITIES_REQUEST = 'GET_INTERNAL_SOURCE_FACILITIES_REQUEST'
export const GET_INTERNAL_SOURCE_FACILITIES_SUCCESS = 'GET_INTERNAL_SOURCE_FACILITIES_SUCCESS'
export const GET_INTERNAL_SOURCE_FACILITIES_FAILURE = 'GET_INTERNAL_SOURCE_FACILITIES_FAILURE'

export const getInternalSourceFacilitiesRequest = () => ({ type: GET_INTERNAL_SOURCE_FACILITIES_REQUEST })
export const getInternalSourceFacilitiesSuccess = (data) => ({ type: GET_INTERNAL_SOURCE_FACILITIES_SUCCESS, data })
export const getInternalSourceFacilitiesFailure = (error) => ({ type: GET_INTERNAL_SOURCE_FACILITIES_FAILURE, payload: error })
