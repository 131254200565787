export const POST_USER_TYPE = 'POST_USER_TYPE'
export const POST_USER_TYPE_REQUEST = 'POST_USER_TYPE_REQUEST'
export const POST_USER_TYPE_SUCCESS = 'POST_USER_TYPE_SUCCESS'
export const POST_USER_TYPE_FAILURE = 'POST_USER_TYPE_FAILURE'

export const postUserTypeRequest = (requestBody) => ({ type: POST_USER_TYPE_REQUEST, payload: { data: requestBody } })
export const postUserTypeSuccess = (data) => {
  return ({ type: POST_USER_TYPE_SUCCESS, data })
}


export const POST_UPDATE_USER = 'POST_UPDATE_USER'
export const POST_UPDATE_USER_REQUEST = 'POST_UPDATE_USER_REQUEST'
export const POST_UPDATE_USER_SUCCESS = 'POST_UPDATE_USER_SUCCESS'
export const POST_UPDATE_USER_FAILURE = 'POST_UPDATE_USER_FAILURE'

export const postUpdateUserRequest = (requestBody) => ({ type: POST_UPDATE_USER_REQUEST, payload: { data: requestBody } })
export const postUpdateUserSuccess = (data) => {
  return ({ type: POST_UPDATE_USER_SUCCESS, data })
}