import store from 'store'
import { LOCAL_STORAGE_PERSISTENT_STATE_KEY } from '../config/constants'
export const loadState = () => {
  try {
    const serializedState = store.get(LOCAL_STORAGE_PERSISTENT_STATE_KEY)
    if (serializedState === null) {
      return undefined
    }
    return serializedState
  } catch (err) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const serializedState = state
    store.set(LOCAL_STORAGE_PERSISTENT_STATE_KEY, serializedState)
  } catch (err) {
    console.log('write to localStorage failed')
  }
}
