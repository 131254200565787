import { GET_GROUP_SUCCESS, POST_CREATE_GROUP_SUCCESS } from '../actions/groupActions'
export default (state = {}, { type, data = {} }) => {
  switch (type) {
    case GET_GROUP_SUCCESS:
        return data
    case POST_CREATE_GROUP_SUCCESS:
        return data
    default:
      return state
  }
}
