import { call, put, takeLatest } from 'redux-saga/effects'
import history from '../routers/history'
import {
  POST_EMAIL_VERIFICATION_CODE_REQUEST,
  POST_EMAIL_VERIFICATION_CODE_FAILURE,
  POST_RESEND_EMAIL_VERIFICATION_REQUEST
} from '../actions/emailVerificationActions'
import { resendEmailVerificationAPICall, sendEmailVerificationCodeAPICall } from '../apis/email-verification'
import {
  clearNotification, dataLoadingNotification,
  errorNotification, successNotification
} from '../utils/notification-helpers'
import { SIGN_UP_STEPS } from '../config/form-settings'
import { t } from "../i18n";
// import { setSliderIndex } from '../actions/sliderActions'

function * sendEmailVerificationCodeHandler ({ payload }) {
  try {
    const { email } = payload
    dataLoadingNotification(t("email_verification_saga.checking_email") + " : " + "'" + email + "'")
    clearNotification()
    dataLoadingNotification(t("email_verification_saga.sending_verification_code") + " " + email)
    const response = yield call(sendEmailVerificationCodeAPICall, email)
    const { responseOk } = response
    clearNotification()
    if (responseOk) {
      successNotification(t("email_verification_saga.verification_code_success") + " " + email)
      // history.replace(`/signup/${SIGN_UP_STEPS['verification'].path}`)
      history.replace(`/signup/${SIGN_UP_STEPS['account'].path}`)
    } else {
      errorNotification(t("email_verification_saga.verification_code_failed") + " " + email)
      history.replace(`/signup/${SIGN_UP_STEPS['failed'].path}`)
    }
    // }
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: POST_EMAIL_VERIFICATION_CODE_FAILURE,
      message: e.message
    })
  }
}


function * resendEmailVerificationHandler ({ payload }) {
  try {
    const obj = {      
      "user_id": payload,
      "client_id": payload    
    }  
    // dataLoadingNotification(t("email_verification_saga.checking_email") + " : " + "'" + email + "'")
    // clearNotification()
    // dataLoadingNotification(t("email_verification_saga.sending_verification_code") + " " + email)
    const response = yield call(resendEmailVerificationAPICall, obj)
    const { responseOk } = response
    clearNotification()
    if (responseOk) {
      successNotification(t("email_verification_saga.success_email_sent"))
    } else {
      errorNotification(t("email_verification_saga.failed_email_sent"))
    }
    
  } catch (e) {
    console.error('ERROR: ', e)
    // yield put({
    //   type: POST_EMAIL_VERIFICATION_CODE_FAILURE,
    //   message: e.message
    // })
  }
}

export function * studyDetailSaga () {
  yield takeLatest(POST_EMAIL_VERIFICATION_CODE_REQUEST, sendEmailVerificationCodeHandler)
  yield takeLatest(POST_RESEND_EMAIL_VERIFICATION_REQUEST, resendEmailVerificationHandler)
}
