import { call, put, takeLatest } from 'redux-saga/effects'
import {
  POST_USER_TYPE_REQUEST,
  POST_USER_TYPE_FAILURE,
  POST_UPDATE_USER_REQUEST
} from '../actions/userTypeActions'
import AuthService from '../services/AuthService'
import { successNotification, errorNotification } from '../utils/notification-helpers'
import { patchUserProfileApiCall, patchUserUpdateApiCall } from '../apis/user'
import history from '../routers/history'
import { t } from "../i18n";
import store from 'store';
import { LOCAL_STORAGE_APPROVED_FACILITIES, LOCAL_STORAGE_NEW_ORGANIZATION_KEY } from '../config/constants';

function* postUserTypeHandler({ payload }) {
  try {

    const auth = new AuthService()
    const { role, link } = payload.data;
    let userProfile = auth.getProfileRequestBodyForUpdate()
    const userId = userProfile?.sub
    const userMemberOf = store.get(LOCAL_STORAGE_NEW_ORGANIZATION_KEY)
    let storedApprovedFacilities = store.get(LOCAL_STORAGE_APPROVED_FACILITIES)
    if (storedApprovedFacilities === undefined) {
      store.set(LOCAL_STORAGE_APPROVED_FACILITIES, [])
      storedApprovedFacilities = []
    }
    const updatedProfile = {
      ...userProfile,
      user_metadata: { ...userProfile.user_metadata, user_type: role },
      app_metadata: {
        affiliated_group: userMemberOf && userMemberOf || '',
        admin: '1',
        main: '1',
        associated_groups: [],
        approved_facilities: storedApprovedFacilities,
      }
    }

    const { responseStatus, responseJSON } = yield call(patchUserProfileApiCall, userId, updatedProfile)
    if (responseStatus === 200) {
      const accessTokenOk = yield call(auth.getNewTokensForAPI)
      if (accessTokenOk) {
        successNotification(t("user_type_saga.add_success"))
        history.replace(link)
      }
    } else {
      responseJSON && responseJSON.message && errorNotification(responseJSON.message)
    }
    // yield store.set(LOCAL_STORAGE_COUNTRY_LIST_KEY, responseJSON)
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: POST_USER_TYPE_FAILURE,
      message: e.message
    })
  }
}


function* postUpdateUserHandler({ payload }) {
  try {
    const auth = new AuthService()
    const { user, link } = payload.data;
    let userProfile = auth.getProfileRequestBodyForUpdate()
    const userId = userProfile?.sub
    const userMemberOf = store.get(LOCAL_STORAGE_NEW_ORGANIZATION_KEY)
    let storedApprovedFacilities = store.get(LOCAL_STORAGE_APPROVED_FACILITIES)
    if (storedApprovedFacilities === undefined) {
      store.set(LOCAL_STORAGE_APPROVED_FACILITIES, [])
      storedApprovedFacilities = []
    }
    const updatedProfile = {
      ...userProfile,
      user_metadata: { ...userProfile.user_metadata },
      app_metadata: {
        affiliated_group: userMemberOf && userMemberOf || '',
        admin: '1',
        main: '1',
        associated_groups: [],
        approved_facilities: storedApprovedFacilities,
      },
      ...user
    }
    const { responseStatus, responseJSON } = yield call(patchUserUpdateApiCall, userId, updatedProfile)
    if (responseStatus === 200) {
      const accessTokenOk = yield call(auth.getNewTokensForAPI)
      if (accessTokenOk) {
        successNotification(t("user_type_saga.add_success"))
        history.replace(link)
      }
    } else {
      responseJSON && responseJSON.message && errorNotification(responseJSON.message)
    }
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: POST_USER_TYPE_FAILURE,
      message: e.message
    })
  }
}

export function* configurationSaga() {
  yield takeLatest(POST_USER_TYPE_REQUEST, postUserTypeHandler)
  yield takeLatest(POST_UPDATE_USER_REQUEST, postUpdateUserHandler)
}
