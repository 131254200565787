import { GROUP_ENDPOINT } from '../config/api-endpoints'
import { authorizeHeader } from '../utils/auth-header'
import { v4 as uuidV4 } from 'uuid'
import { uploadFile } from '../utils/azure-blob-helpers'
import i18n from '../i18n';

import {
  showNotificationForUploadInProgress,
  showNotificationForUploadFailed,
  showNotificationForUploadEnd
} from '../utils/notification-helpers'

export const getGroupAPICall = async () => {
  try {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const response = await window.fetch(GROUP_ENDPOINT, authorizeHeader(config))
    const responseOk = await response.status === 200
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const getCheckGroupAPICall = async ({ userGroupName }) => {
  try {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const response = await window.fetch(GROUP_ENDPOINT + '/' + encodeURIComponent(userGroupName), authorizeHeader(config))
    const responseOk = await response.status === 200
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const postCreateGroupAPICall = async ({ userGroupName, address, email_address }) => {
  try {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name: userGroupName, address, email_address })
    }
    const response = await window.fetch(GROUP_ENDPOINT + "?new-customer=yes", authorizeHeader(config))
    const responseOk = await response.status === 200
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}


export const postUpdateGroupAPICall = async ({ userGroupCountry, userGroupEmail, userGroupName, userPhone, userGroupLogo, website, groupId, containerURL }) => {
  try {
    let logo = ''
    const t = i18n.t;
    if (containerURL) {
      const notification = {
        notificationKey: 'upload',
        message: t("apis.groups.post_update_message"),
        content: t("apis.upload_image_file")
      }

      const file = userGroupLogo[0]
      const fileName = `${groupId}-${uuidV4()}.${file.name.split('.', -1)[1]
        }`

      const response = await uploadFile({ containerURL, file, fileName, showNotificationForUploadInProgress, showNotificationForUploadFailed, notification })
      if (response) {
        logo = `https://teleradsettings.blob.core.windows.net/grouplogos/${fileName}`
      }
      showNotificationForUploadEnd(notification)

    }
    const config = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ location: userGroupCountry, email_address: userGroupEmail, name: userGroupName, logo, website: website, office_number: userPhone && userPhone.phone })
    }
    const response = await window.fetch(GROUP_ENDPOINT, authorizeHeader(config))
    const responseOk = await response.status === 200
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}
