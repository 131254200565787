import { call, put, takeLatest } from 'redux-saga/effects'
import store from 'store'
import history from '../routers/history'
import { change } from 'redux-form'

import {
  clearNotification, errorNotification, successNotification
  // errorNotification, successNotification
} from '../utils/notification-helpers'
import { LOCAL_STORAGE_NEW_ORGANIZATION_KEY } from '../config/constants'
import { postCreateInternalRadiologistSuccess, POST_CREATE_INTERNAL_RADIOLOGIST_FAILURE, POST_CREATE_INTERNAL_RADIOLOGIST_REQUEST } from '../actions/internalRadiologistActions'
import { postCreateInternalRadiologistAPICall } from '../apis/internal-radiologist'
import { SIGN_UP_STEPS, USER_FORM_NAME } from '../config/form-settings'
import { postNotificationToSlackAPICall } from '../apis/slack'
import AuthService from '../services/AuthService'
import { t } from "../i18n";

function * postCreateInternalRadiologistHandler ({ payload }) {
  try {

    const { data } = payload
    const userMemberOf = store.get(LOCAL_STORAGE_NEW_ORGANIZATION_KEY)

    let updatedRadiologist = {
      name: data.name,
      email: data.email,
      app_metadata: {
        affiliated_group: data.userType==='Radiologist' ? userMemberOf : '',
        admin: '0',
        main: '0',
        approved_facilities: [],
        associated_groups: data.userType!=='Radiologist' ? [userMemberOf] : []
      },
      user_metadata:{
        name: data.name,
        user_type: 'radiologist',
        specialty: data.subSpecialty,
        title:'',
        home_facility:'',
        credentials:''
      }
    }

    const response =  yield call(postCreateInternalRadiologistAPICall, updatedRadiologist)
    if(response.responseOk){
      successNotification(t("internal_radiologist_saga.success_add_radiologist"))
      yield put(postCreateInternalRadiologistSuccess())
      history.replace(`/signup/${SIGN_UP_STEPS['invite'].path}`)
    } else{
      if(response && response.responseJSON){
        if (response.responseJSON.message === "The user already exists." && response.responseJSON.statusCode === 409) {
          const auth = new AuthService()
          const userProfile = auth.getUserProfile()
          yield call(postNotificationToSlackAPICall, { title: '[User Invitation - Sign Up]:', jsonContent: {inviter: userProfile.name, invitees: JSON.stringify(updatedRadiologist)},description: `A user invitation has been sent by ${userProfile.name}`, sendToTeleradOps: true })
          throw new Error("Some of your radiologists already have AlemHealth Connect accounts. We will let them know you would like to share studies with them.")
        }
        throw new Error(response.responseJSON.message)
      }
        throw new Error("Failed to add radiologist")
    }

  } catch (e) {
    clearNotification()
    errorNotification(e.message)
    console.error('ERROR: ', e)
    yield put({
      type: POST_CREATE_INTERNAL_RADIOLOGIST_FAILURE,
      message: e.message
    })
    yield put(change(USER_FORM_NAME, 'radiologists', []))
  }
}
export function * internalRadiologistSaga () {
  yield takeLatest(POST_CREATE_INTERNAL_RADIOLOGIST_REQUEST, postCreateInternalRadiologistHandler)
}
