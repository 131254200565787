import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import throttle from 'lodash/throttle'
import rootReducer from '../reducers'
import combinedSaga from '../sagas'
import { saveState } from './localStorage'

const sagaMiddleware = createSagaMiddleware()
const finalCreateStore = compose(
  applyMiddleware(sagaMiddleware)
)(createStore)

export default function configureStore (initialState) {
  const store = finalCreateStore(rootReducer, initialState)
  sagaMiddleware.run(combinedSaga)
  store.subscribe(throttle(() => {
    saveState({
      configurationData: store.getState().configurationData
    })
  }, 1000))
  return store
}
