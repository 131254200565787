import { t } from "../i18n";

export const SIGN_UP_FORM_NAME = 'signUpForm'
export const USER_FORM_NAME = 'userForm'
export const USER_INFO_FORM_NAME = 'userInfoForm'
export const USER_TYPE_FORM = 'userTypeForm'
export const FACILITY_FORM_NAME = 'facilityForm'
export const PROVIDER_FORM_NAME = 'providerForm'
export const FACILITY_ACCESS_FORM_NAME = 'facilityAccessForm'
export const INVITE_FORM_NAME = 'inviteForm'

export const EMAIL_VERIFICATION_CODE_LENGTH = 6

export const USER_FORM_STEPS = {
  STEP_01_USER_INFO: 0,
  STEP_02_EMAIL_VERIFICATION: 1,
  STEP_03_USER_PASSWORD: 2,
  STEP_04_SELECT_ROLE: 3,

  STEP_05_X_USER_ORGANIZATION: 4,
  STEP_05_A_USER_FACILITY: 5,
  STEP_05_B_PROVIDER_FACILITY: 6,
  STEP_06_X_REQUEST_USER_ORGANIZATION_ACCESS: 7,
  STEP_06_A_REQUEST_USER_FACILITY_ACCESS: 8,
  STEP_06_B_REQUEST_PROVIDER_FACILITY_ACCESS: 9,

  STEP_07_A_COLLEAGUE: 10,
  STEP_07_B_PROVIDER_FACILITY_INVITE: 11,

  STEP_08_A_PROCESS_RADIOLOGIST_PENDING: 12,
  STEP_08_B_PROCESS_RADIOLOGIST_COMPLETED: 13,
  STEP_08_C_PROCESS_INDEPENDENT_RADIOLOGIST_PENDING: 14,
  STEP_08_D_PROCESS_FAILED: 15
}

export const userRoles = [
  {
    title: t('forms.helpers.select_user_card.radiologist.title'),
    description: t('forms.helpers.select_user_card.radiologist.description'),
    value: 'radiologist'
  },
  {
    title: t('forms.helpers.select_user_card.radiologist_assistant.description'),
    description: t('forms.helpers.select_user_card.radiologist_assistant.description'),
    value: 'radiologist assistant'
  },
  {
    title: t('forms.helpers.select_user_card.radiographer.title'),
    description: t('forms.helpers.select_user_card.radiographer.title'),
    value: 'radiographer'
  },
  {
    title: t('forms.helpers.select_user_card.administrator.title'),
    description: t('forms.helpers.select_user_card.administrator.title'),
    value: 'administrator'
  },
  {
    title: t('forms.helpers.select_user_card.other.title'),
    description: t('forms.helpers.select_user_card.other.title'),
    value: 'other'
  }
]

export const USER_ROLES_OPTIONS = [
  { key: 'radiologist', value: 'Radiologist' },
  { key: 'independent radiologist', value: 'Independent Radiologist' },
  { key: 'radiographer', value: 'Radiographer' },
  { key: 'coordinator', value: 'Coordinator' },
  { key: 'radiologist assistant', value: 'Radiologist Assistant' },
  { key: 'admin', value: 'Admin' },
  { key: 'other', value: 'Other' }
]

export const FACILITY_TYPE_OPTIONS = [
  { key: 'diagnostic-imaging-center', value: 'Diagnostic Imaging Centre' },
  { key: 'hospital', value: 'Hospital' },
  { key: 'health-clinic', value: 'Health Clinic' },
  { key: 'senior-living-facility', value: 'Assisted/Senior Living Facility' },
  { key: 'teleradiology-provider', value: 'Teleradiology Provider' },
  { key: 'other', value: 'Other' }
]

export const contractOptions = {
  INDEPENDENT_RADIOLOGIST: 'independent radiologist',
  FACILITY: 'facility'
}

export const initialFormValue = {
  userName: '',
  userEmail: '',
  userPhone: '',
  userEmailVerificationCode: '',
  userPassword: '',
  userType: { role: '', contractType: contractOptions.FACILITY },
  userGroupName: '',
  userGroupCountry: '',
  userGroupEmail: '',
  userGroupPhone: '',
  userGroupFacilities: [],
  userFacilityName: '',
  userFacilityCountry: '',
  userFacilityEmail: '',
  userFacilityPhone: '',
  userFacilityType: '',
  userFacilityRequestAccessMessage: '',
  providerFacilities: [
    { name: '', country: '', email: '', phone: '' }
  ],
  invitees: [
    { name: '', email: '' }
  ],
  colleagues: [
  ]
}


export const initialSignUpFormValue = {
  userName: '',
  userEmail: '',
  userPhone: '',
  userEmailVerificationCode: '',
  userPassword: '',
  userType: { role: '', contractType: contractOptions.FACILITY },
  accountType: '',
  userGroupName: '',
  userGroupCountry: '',
  userGroupEmail: '',
  userGroupPhone: '',
  userGroupFacilities: [],
  userFacility:{},
  userFacilityRequestAccessMessage: '',
  providerFacilities: [
    { name: '', country: '', email: '', phone: '' }
  ],
  invitees: [
    { name: '', email: '' }
  ],
  colleagues: [
  ],
  radiologists: [
  ]
}

// export const SIGN_UP_STEPS = {
//   'account-type'  : { path: 'account-type', step: 0},
//   'user-type'  : { path:  'user-type', step: 1},
//   'account' : { path:  'account', step: 2},
//   'verification' : { path:  'verification', step: 3},
//   'password' : { path:  'password', step: 4},
//   'radiology-group' : { path:  'radiology-group', step: 5},
//   'radiology-group-detail': { path: 'radiology-group-detail', step: 6},
//   'main-facility': { path: 'main-facility', step: 7},
//   'facility': { path: 'facility', step: 8},
//   'facility-detail': { path: 'facility-detail', step: 9},
//   'add-another-facility': { path: 'add-another-facility', step: 10},
//   'facility-exist': { path: 'facility-exist', step: 11},
//   'second-opinion': { path: 'second-opinion', step: 12},
//   'add-radiologist-intro': { path: 'add-radiologist-intro', step: 13},
//   'add-radiologist': { path: 'add-radiologist', step: 14},
//   'invite': { path: 'invite', step: 15},
//   'success': { path: 'success', step: 16},
//   'failed': { path: 'failed', step: 17}
// }

export const SIGN_UP_STEPS = {
  'user-type'  : { path:  'user-type', step: 0, showInProgress: true},
  'account' : { path:  'account', step: 1, showInProgress: true},
  // 'verify-account' : { path:  'verify-account', step: 2, showInProgress: true},
  'medical-groups' : { path: 'medical-groups', step: 3, showInProgress: true},
  'medical-group-create'  : { path: 'medical-group-create', step: 4, showInProgress: true},
  // 'invite': { path: 'invite', step: 3, showInProgress: true},
  'success': { path: 'success', step: 5,showInProgress: true},
  'failed': { path: 'failed', step: 6}
}

export const SIGN_UP_STEPS_INDEX = [
  'user-type',
  'account',
  // 'verify-account',
  'medical-groups',
  'medical-group-create',
  // 'invite',
  'success',
  'failed'
]


// export const USER_TYPE = [
//   { label: 'View All Studies', value: 'Radiologist' },
//   { label: 'View Only Assigned Studies', value: 'Independent Radiologist' }
// ];

export const RAD_SPECIALTY_TYPE = [
  { label: 'Abdominal Imaging', value: 'Abdominal Imaging' },
  { label: 'Breast Imaging', value: 'Breast Imaging' },
  { label: 'Cardiac Imaging', value: 'Cardiac Imaging' },
  { label: 'Emergency Radiology', value: 'Emergency Radiology' },
  { label: 'Musculoskeletal Imaging', value: 'Musculoskeletal Imaging' },
  {
    label: 'Neuro-interventional Radiology',
    value: 'Neuro-interventional Radiology'
  },
  { label: 'Neuroradiology', value: 'Neuroradiology' },
  { label: 'Nuclear Medicine', value: 'Nuclear Medicine' },
  { label: 'Oncological Radiology', value: 'Oncological Radiology' },
  { label: 'Pediatric Radiology', value: 'Pediatric Radiology' },
  { label: 'Prostate Radiology', value: 'Prostate Radiology' },
  { label: 'Thoracic Imaging', value: 'Thoracic Imaging' },
  {
    label: 'Vascular Interventional Radiology',
    value: 'Vascular Interventional Radiology'
  }
];

export const USER_TYPE = [
  { label: "user_type.facility_administrator", value: "Administrator" },
  { label: "user_type.radiology_coordinator", value: "Coordinator" },
  { label: "user_type.general_management", value: "Management" },
  { label: "user_type.it_management", value: "IT Management" },
  { label: "user_type.student_or_researcher", value: "Researcher" }
];

// export const USER_TYPE = [
//   { label: "user_type.management", value: "Management" },
//   { label: "user_type.clinician", value: "Clinician" },
//   { label: "user_type.radiographer", value: "Radiographer" },
//   { label: "user_type.radiologist", value: "Radiologist" },
//   {
//     label: "user_type.radiologist_assistant",
//     value: "Radiologist Assistant",
//   },
//   { label: "user_type.coordinator", value: "Coordinator" },
//   { label: "user_type.administrator", value: "Administrator" },
// ];
