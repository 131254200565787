import { USER_EXISTING_ENDPOINT } from '../config/api-endpoints'
import { authorizeHeader } from '../utils/auth-header'

export const postCreateInternalRadiologistAPICall = async (requestData) => {
  try {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    }
    const response = await window.fetch(USER_EXISTING_ENDPOINT, authorizeHeader(config))
    const responseOk = await response.status === 201
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}
