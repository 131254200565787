import { STUDY_ASSIGNMENT_REQUEST_ENDPOINT, STUDY_GET_REQUEST_ENDPOINT, STUDY_SHARE_ENDPOINT } from '../config/api-endpoints'
import { authorizeHeader } from '../utils/auth-header'

export const putAssignStudyAPICall = async (guid, userGuid) => {
  try {
    const config = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ radiologist_id: userGuid })
    }

    const response = await window.fetch(STUDY_ASSIGNMENT_REQUEST_ENDPOINT + guid + "/", authorizeHeader(config))
    const responseOk = await response.status === 200
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const getSingleStudyAPICall = async (guid) => {
  try {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const response = await window.fetch(STUDY_GET_REQUEST_ENDPOINT + guid + "/", authorizeHeader(config))
    const responseOk = await response.status === 200
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const postStudyShareAPICall = async ({ guid }) => {
  try {
    const config = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const response = await window.fetch(STUDY_SHARE_ENDPOINT + guid + '/', authorizeHeader(config))
    const responseStatus = await response.status
    return { responseStatus }
  } catch (e) {
    console.error(e)
  }
}
