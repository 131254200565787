import { call, put, takeLatest } from "redux-saga/effects";
import { getUserLocationSuccess, GET_USER_LOCATION_REQUEST } from "../actions/userLocationAction";
import { getCurrentIPInfoAPICall } from "../apis/location";

function * getUserLocationHandler (){
    try {
        const response = yield call(getCurrentIPInfoAPICall)
        if (response) {
          yield put(getUserLocationSuccess(response))
        }
      } catch (e) {
        console.error('ERROR: ', e)
      }
}
export function * userLocationSaga () {
    yield takeLatest(GET_USER_LOCATION_REQUEST, getUserLocationHandler)
}