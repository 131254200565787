import { combineReducers } from 'redux'
import loadingReducers from './loadingReducers'
import facilityCheckReducers from './facilityCheckReducers'
import medicialGroupReducers from './medicialGroupReducers'
import medicialGroupInvitationsReducers from './medicialGroupInvitationsReducers'
import groupReducers from './groupReducers'
import groupCheckReducers from './groupCheckReducers'
import invitationAccepted from './invitationAcceptedRaducers'
import sliderReducers from './sliderReducers'
import userLocationReducers from './userLocationReducers'
import studyShareReducers from './studyShareReducers'
import { reducer as formReducers } from 'redux-form'

const rootReducers = combineReducers({
  facilityExists: facilityCheckReducers,
  organizationDomain: medicialGroupReducers,
  medicalGroupsInvitstions: medicialGroupInvitationsReducers,
  group: groupReducers,
  groupExists: groupCheckReducers,
  invitationAccepted,
  form: formReducers,
  isLoading: loadingReducers,
  slider: sliderReducers,
  userLocation: userLocationReducers,
  studyShare: studyShareReducers,
})

export default rootReducers
