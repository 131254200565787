import { FACILITY_ENDPOINT, INTERNAL_SOURCE_FACILITY_ENDPOINT } from '../config/api-endpoints'
import { authorizeHeader } from '../utils/auth-header'
import { v4 as uuidV4 } from 'uuid'
import { uploadFile } from '../utils/azure-blob-helpers'
import {
  showNotificationForUploadInProgress,
  showNotificationForUploadFailed,
  showNotificationForUploadEnd
} from '../utils/notification-helpers'
import i18n from '../i18n';

export const postCreateFacilityAPICall = async ({ userFacilityAddress, userFacilityCity, userFacilityCountry, userFacilityEmail, userFacilityName, userFacilityPhone, userFacilityType, userFacilityLogo, containerURL, userMemberOf, hideProgress }) => {
  try {

    let logo = ''
    const t = i18n.t;
    if(userFacilityLogo && userFacilityLogo[0]){
      const notification = {
        notificationKey: 'upload',
        message: t("apis.facility.create_message"),
        content: t("apis.upload_image_file")
      }

      const file = userFacilityLogo[0]
      const fileName = `${uuidV4()}.${
        file.name.split('.', -1)[1]
      }`

      const response = await uploadFile({ containerURL, file, fileName, showNotificationForUploadInProgress, showNotificationForUploadFailed, notification, hideProgress })
      if (response) {
        logo = `https://teleradsettings.blob.core.windows.net/facilitylogos/${fileName}`
      }
      showNotificationForUploadEnd(notification)

    }

    const userSinglePhone = (userFacilityPhone && userFacilityPhone.phone)
    const guid = uuidV4()
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ location: userFacilityAddress, city: userFacilityCity, guid: guid, country: userFacilityCountry, email: userFacilityEmail, facility_type: userFacilityType, name: userFacilityName, facility_logo: logo, contact_number_1: userSinglePhone, member_of: userMemberOf })
    }
    const response = await window.fetch(FACILITY_ENDPOINT, authorizeHeader(config))
    const responseOk = await response.status === 201
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}


export const postUpdateFacilityAPICall = async ({ guid, userFacilityAddress, userFacilityCity, userFacilityCountry, userFacilityEmail, userFacilityName, userFacilityPhone, userFacilityType, userFacilityLogo, containerURL, userMemberOf }) => {
  try {
    const userSinglePhone = (userFacilityPhone && userFacilityPhone.phone)
    const config = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ location: userFacilityAddress, city: userFacilityCity, country: userFacilityCountry, email: userFacilityEmail, facility_type: userFacilityType, name: userFacilityName, contact_number_1: userSinglePhone, member_of: userMemberOf })
    }
    const response = await window.fetch(`${FACILITY_ENDPOINT}/${guid}`, authorizeHeader(config))
    const responseOk = await response.status === 201
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const getInternalSourceFacilitiesCall = async () => {
  try {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    let endpointToHit = INTERNAL_SOURCE_FACILITY_ENDPOINT
    const response = await window.fetch(endpointToHit, authorizeHeader(config))
    const responseStatus = await response.status
    let responseJSON;
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      responseJSON = await response.json()
    } else{
      throw Error(`Response is not a JSON.`);
    }
    return { responseStatus, responseJSON }
  } catch (e) {
    console.error(e)
  }
}
