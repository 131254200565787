export const POST_EMAIL_VERIFICATION_CODE = 'POST_EMAIL_VERIFICATION_CODE'
export const POST_EMAIL_VERIFICATION_CODE_REQUEST = 'POST_EMAIL_VERIFICATION_CODE_REQUEST'
export const POST_RESEND_EMAIL_VERIFICATION_REQUEST = 'POST_RESEND_EMAIL_VERIFICATION_REQUEST'
export const POST_EMAIL_VERIFICATION_CODE_SUCCESS = 'POST_EMAIL_VERIFICATION_CODE_SUCCESS'
export const POST_EMAIL_VERIFICATION_CODE_FAILURE = 'POST_EMAIL_VERIFICATION_CODE_FAILURE'

export const postEmailVerificationCodeRequest = (email) => ({ type: POST_EMAIL_VERIFICATION_CODE_REQUEST, payload: { email } })
export const postResendEmailVerificationRequest = (payload) => ({ type: POST_RESEND_EMAIL_VERIFICATION_REQUEST, payload: payload })
export const postEmailVerificationCodeSuccess = (data) => {
  return ({ type: POST_EMAIL_VERIFICATION_CODE_SUCCESS, data })
}
