export const POST_INVITE_COLLEAGUE = 'POST_INVITE_COLLEAGUE'
export const POST_INVITE_COLLEAGUE_REQUEST = 'POST_INVITE_COLLEAGUE_REQUEST'
export const POST_INVITE_COLLEAGUE_SUCCESS = 'POST_INVITE_COLLEAGUE_SUCCESS'
export const POST_INVITE_COLLEAGUE_FAILURE = 'POST_INVITE_COLLEAGUE_FAILURE'

export const postInviteColleagueRequest = (requestBody) => ({ type: POST_INVITE_COLLEAGUE_REQUEST, payload: {data: requestBody} })
export const postInviteColleagueSuccess = (data) => {
  return ({ type: POST_INVITE_COLLEAGUE_SUCCESS, data })
}

export const POST_SOURCE_ORGANIZATIONS = 'POST_SOURCE_ORGANIZATIONS'
export const POST_SOURCE_ORGANIZATIONS_REQUEST = 'POST_SOURCE_ORGANIZATIONS_REQUEST'
export const POST_SOURCE_ORGANIZATIONS_SUCCESS = 'POST_SOURCE_ORGANIZATIONS_SUCCESS'
export const POST_SOURCE_ORGANIZATIONS_FAILURE = 'POST_SOURCE_ORGANIZATIONS_FAILURE'

export const postSourceOrganizationsRequest = (sourceOrganizations) => ({ type: POST_SOURCE_ORGANIZATIONS_REQUEST, payload: sourceOrganizations })
export const postSourceOrganizationsSuccess = (data) => {
  return ({ type: POST_SOURCE_ORGANIZATIONS_SUCCESS, data })
}

export const POST_INVITE = 'POST_INVITE'
export const POST_INVITE_REQUEST = 'POST_INVITE_REQUEST'
export const POST_INVITE_SUCCESS = 'POST_INVITE_SUCCESS'
export const POST_INVITE_FAILURE = 'POST_INVITE_FAILURE'

export const postInviteRequest = (emails) => ({ type: POST_INVITE_REQUEST, payload: emails })
export const postInviteSuccess = (data) => ({ type: POST_INVITE_SUCCESS, data })

export const POST_WELCOME_EMAIL = 'POST_WELCOME_EMAIL'
export const POST_WELCOME_EMAIL_REQUEST = 'POST_WELCOME_EMAIL_REQUEST'
export const POST_WELCOME_EMAIL_SUCCESS = 'POST_WELCOME_EMAIL_SUCCESS'
export const POST_WELCOME_EMAIL_FAILURE = 'POST_WELCOME_EMAIL_FAILURE'

export const postWelcomeEmailRequest = (requestBody) => ({ type: POST_WELCOME_EMAIL_REQUEST, payload: requestBody })
export const postWelcomeEmailSuccess = (data) => {
  return ({ type: POST_WELCOME_EMAIL_SUCCESS, data })
}
