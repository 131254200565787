// const localAPIGateway = 'http://localhost:8000/api'
// const prodAPIGateway = 'https://telerad-portal-api.cloud.alem.health/signup/api'
// const devAPIGateway = 'https://prod-telerad-portal-api.cloud.alem.health/signup/api'
// const prodAPIGateway = 'https://alemhealthapi.azure-api.net/signup/api'
// const environmentToAPIMapping = {
//   'local': prodAPIGateway,
//   'development': prodAPIGateway,
//   'production': prodAPIGateway
// }
// const API_BASE_URL = environmentToAPIMapping[process.env.REACT_APP_ENV]


const POST_SIGNUP_ROOT_URL_INVITATION = 'https://alemhealthapi.azure-api.net/signup-invite'
const PRE_SIGNUP_ROOT_URL = 'https://alemhealthapi.azure-api.net/signup/api'
const POST_SIGNUP_ROOT_URL = 'https://alemhealthapi.azure-api.net'
const JIGSAWSTACK_ROOT_URL = 'https://api.jigsawstack.com'

export const RESEND_EMAIL_VERIFICATION = POST_SIGNUP_ROOT_URL + '/teleradsetting/api/users/verification-email'
export const SEND_EMAIL_VERIFICATION_CODE_ENDPOINT = PRE_SIGNUP_ROOT_URL + '/email-verification/send-code/'
export const VERIFY_EMAIL_VERIFICATION_CODE_ENDPOINT = PRE_SIGNUP_ROOT_URL + '/email-verification/verify-code/'

export const INTERNAL_SOURCE_FACILITY_ENDPOINT = POST_SIGNUP_ROOT_URL + '/ahfacility/facilities/ingroup/'
export const FACILITY_ENDPOINT = POST_SIGNUP_ROOT_URL + '/ahfacility/facility'
export const GROUP_ENDPOINT = POST_SIGNUP_ROOT_URL + '/teleradsetting/api/group'
export const PATCH_USER_PROFILE_ENDPOINT = POST_SIGNUP_ROOT_URL + '/teleradsetting/api/users/existing'
export const PATCH_USER_ENDPOINT = POST_SIGNUP_ROOT_URL + '/teleradsetting/api/users/'
export const USER_EXISTING_ENDPOINT = POST_SIGNUP_ROOT_URL + '/teleradsetting/api/users'
export const USER_NEW_ENDPOINT = POST_SIGNUP_ROOT_URL + '/teleradsetting/api/users/new'
export const INVITE_ENDPOINT = POST_SIGNUP_ROOT_URL + '/signup/email/invites/'
export const WELCOME_EMAIL_ENDPOINT = POST_SIGNUP_ROOT_URL + '/signup/email/templated'
export const INTERNAL_RADIOLOGISTS_ENDPOINT = POST_SIGNUP_ROOT_URL + '/teleradsetting/api/internal-radiologists'

export const PUT_SIGN_UP_ACCEPT_REJECT = POST_SIGNUP_ROOT_URL_INVITATION + '/api'

export const STUDY_DEMO_REQUEST_ENDPOINT = POST_SIGNUP_ROOT_URL + '/onboarding/api/demo-study/uploads/'
export const MEDICAL_GROUP_INVITATION_ENDPOINT = POST_SIGNUP_ROOT_URL_INVITATION + '/api/invitations/user/'
export const MEDICAL_GROUP_INVITATION_DOMAIN_ENDPOINT = POST_SIGNUP_ROOT_URL_INVITATION + '/api/organization/domains/'
export const MEDICAL_GROUP_ACCESS_REQUEST_ENDPOINT = POST_SIGNUP_ROOT_URL_INVITATION + '/api/organization/'
export const MEDICAL_GROUP_ACCEPT_INVITATION_REQUEST_ENDPOINT = POST_SIGNUP_ROOT_URL_INVITATION + '/api/invitations/'

export const STUDY_ASSIGNMENT_REQUEST_ENDPOINT = POST_SIGNUP_ROOT_URL + '/assign/api/assignments/'
export const STUDY_GET_REQUEST_ENDPOINT = POST_SIGNUP_ROOT_URL + '/study/api/studies/'
export const STUDY_SHARE_ENDPOINT = POST_SIGNUP_ROOT_URL + '/study-sharing-request/api/study-sharing/apply-request/'
export const VERIFY_EMAIL_ADDRESS_ENDPOINT = JIGSAWSTACK_ROOT_URL + '/v1/validate/email'

export const COUNTRY_LIST_ENDPOINT = 'https://restcountries.com/v2/all'

export const PRE_SIGNUP_SUBSCRIPTION_KEY = '1689c4acdc4945618e14fdbdd1976beb'
export const POST_SIGNUP_SUBSCRIPTION_KEY = '1689c4acdc4945618e14fdbdd1976beb'

export const JIGSAWSTACK_PUBLIC_KEY = 'pk_b53e44c4cfa4f483327c619c6078bfcd69b2a4619878d2a7477aa9415f4c849106f5c045b1cc703d2688bd97ec4600f4f226a6dabbd48d097058a90a83c992a80249B52uJyGFeHzvrHyv1'

export const AZURE_ADDRESS_ENDPOINT = POST_SIGNUP_ROOT_URL + '/teleradsetting/api/options/get_azure'