import { INVITE_ENDPOINT, MEDICAL_GROUP_ACCESS_REQUEST_ENDPOINT, WELCOME_EMAIL_ENDPOINT } from '../config/api-endpoints'
import { authorizeHeader } from '../utils/auth-header'
import { CONNECT_APP_URL, WELCOME_MESSAGE_TEMPLATE_ID } from '../config/constants'

export const postInviteColleagueAPICall = async (requestBody) => {
  try {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    }
    const response = await window.fetch(MEDICAL_GROUP_ACCESS_REQUEST_ENDPOINT + requestBody.organization_guid + "/invitation-email/", authorizeHeader(config))
    const responseStatus = await response.status
    const responseOk = responseStatus === 200 || responseStatus === 201
    return { responseOk }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const postWelcomeEmailAPICall = async (userProfile) => {
  try {
    const welcomeMessageContent = [
      {
        email: userProfile && userProfile.email,
        context: {
          full_name: userProfile && userProfile.name,
          login_url: CONNECT_APP_URL
        }
      }
    ]
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(welcomeMessageContent)
    }
    const response = await window.fetch(WELCOME_EMAIL_ENDPOINT + '/' + encodeURIComponent(WELCOME_MESSAGE_TEMPLATE_ID) + '/', authorizeHeader(config))
    const responseStatus = await response.status
    const responseOk = responseStatus === 200 || responseStatus === 201
    return { responseOk }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}
