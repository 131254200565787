
export const POST_CREATE_INTERNAL_RADIOLOGIST = 'POST_CREATE_INTERNAL_RADIOLOGIST'
export const POST_CREATE_INTERNAL_RADIOLOGIST_REQUEST = 'POST_CREATE_INTERNAL_RADIOLOGIST_REQUEST'
export const POST_CREATE_INTERNAL_RADIOLOGIST_SUCCESS = 'POST_CREATE_INTERNAL_RADIOLOGIST_SUCCESS'
export const POST_CREATE_INTERNAL_RADIOLOGIST_FAILURE = 'POST_CREATE_INTERNAL_RADIOLOGIST_FAILURE'

export const postCreateInternalRadiologistRequest = (data) => ({ type: POST_CREATE_INTERNAL_RADIOLOGIST_REQUEST, payload: { data } })
export const postCreateInternalRadiologistSuccess = (data) => {
  return ({ type: POST_CREATE_INTERNAL_RADIOLOGIST_SUCCESS, data })
}
