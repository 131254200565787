import { JIGSAWSTACK_PUBLIC_KEY, RESEND_EMAIL_VERIFICATION, SEND_EMAIL_VERIFICATION_CODE_ENDPOINT, VERIFY_EMAIL_ADDRESS_ENDPOINT, VERIFY_EMAIL_VERIFICATION_CODE_ENDPOINT } from '../config/api-endpoints'
import { authorizeHeader, configureHeaderForEmailVerification } from '../utils/auth-header'

export const sendEmailVerificationCodeAPICall = async (email) => {
  try {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    }
    const response = await window.fetch(SEND_EMAIL_VERIFICATION_CODE_ENDPOINT, configureHeaderForEmailVerification(config))
    const responseOk = await response.status === 200
    return { responseOk }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const resendEmailVerificationAPICall = async (payload) => {
  try {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }
    const response = await window.fetch(RESEND_EMAIL_VERIFICATION, authorizeHeader(config))
    const responseOk =  response.status === 200 ||  response.status === 201
    return { responseOk }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}


export const validateEmailVerificationCodeAPICall = async (email, verificationCode) => {
  try {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, code: verificationCode })
    }
    const response = await window.fetch(VERIFY_EMAIL_VERIFICATION_CODE_ENDPOINT, configureHeaderForEmailVerification(config))
    const responseOk = await response.status === 200
    return { responseOk }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

export const verifyEmailAddressAPICall = async (email, callback) => {
  try {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': JIGSAWSTACK_PUBLIC_KEY
      },
    }
    
    // create query string with email key
    const queryString = `?email=${email}`

    const response = await window.fetch(VERIFY_EMAIL_ADDRESS_ENDPOINT + queryString, config)
    const responseOk = response.status === 200
    const responseJSON = await response.json()
    if (callback) {
      callback(null, { responseOk, responseJSON });
    } else {
      return { responseOk, responseJSON };
    }
  } catch (e) {
    console.error('ERROR: ', e)
    if (callback) {
      callback(e);
    } else {
      throw e;
    }
  }
}
