import { GET_MEDICAL_GROUPS_INVITATIONS_LIST_SUCCESS } from '../actions/medicialGroupActions'

export default (state = {status: "idle"}, { type, data = {} }) => {
    switch (type) {
      case GET_MEDICAL_GROUPS_INVITATIONS_LIST_SUCCESS: {
        return {
          status: "success",
          data
        }
      }
      default:
        return state
    }
  }