import { authorizeHeader } from '../utils/auth-header'
import store from 'store'
import { AZURE_ADDRESS_ENDPOINT } from '../config/api-endpoints';
import decrypt from '../utils/crypto'
import {
  AnonymousCredential,
  StorageURL,
  ServiceURL,
  ContainerURL,
  BlobURL,
  BlockBlobURL,
  Aborter
} from '@azure/storage-blob';
import { LOCAL_STORAGE_AUTH_KEY } from '../config/constants';

export const getAzureAddressApiCall = async (containerName)=> {
  try {
    const accessToken = store.get((false) ? LOCAL_STORAGE_AUTH_KEY.MGMT_ACCESS_TOKEN : LOCAL_STORAGE_AUTH_KEY.ACCESS_TOKEN)

    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const response = await window.fetch(AZURE_ADDRESS_ENDPOINT, authorizeHeader(config))
    const responseOk = await response.status === 200
    const responseJSON = await response.json()

    const anonymousCredential = new AnonymousCredential();
    const pipeline = StorageURL.newPipeline(anonymousCredential);
    const serviceURL = new ServiceURL(decrypt(accessToken, responseJSON.message), pipeline);

    let containerURL = null

    if(responseOk){
       containerURL = ContainerURL.fromServiceURL(serviceURL, containerName);
    }

    return containerURL
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}