// import { AZURE_STORAGE_ACCOUNT_NAME, AZURE_CONTAINER } from '../config/azure-settings'

import {
  AnonymousCredential,
  StorageURL,
  ServiceURL,
  ContainerURL,
  BlobURL,
  BlockBlobURL,
  Aborter,
  uploadBrowserDataToBlockBlob
} from '@azure/storage-blob';


export const fileToDataURL = (file) => {
  const reader = new window.FileReader()
  return new Promise((resolve) => {
    reader.onload = ev => {
      resolve(ev.target.result)
    }
    reader.readAsDataURL(file)
  })
}

// const imageToBlob = (src) => {
//   const img = new window.Image()
//   return new Promise((resolve) => {
//     img.onload = () => {
//       const canvas = document.createElement('canvas')
//       canvas.width = img.naturalWidth
//       canvas.height = img.naturalHeight
//       canvas.getContext('2d').drawImage(img, 0, 0)
//       canvas.toBlob(resolve, 'image/png')
//     }
//     img.src = src
//   })
// }

// export const transformFile = async (file) => {
//   const fileType = file.type
//   const dataURL = await fileToDataURL(file)
//   const blob = await imageToBlob(dataURL)
//   if (fileType.indexOf('image') === -1 || fileType === 'image/png' || fileType === 'image/svg+xml') {

//     if(fileType === 'image/svg+xml'){
//       blob.name = file.name.replace(/\.[^.]+$/g, '.svg')
//     }
//     else{
//       blob.name = file.name.replace(/\.[^.]+$/g, '.png')
//     }
//     blob.name = blob.name.replace(/ /g,"_")

//     return blob
//   }

//   blob.name = file.name.replace(/\.[^.]+$/g, '.png')
//   blob.name = blob.name.replace(/ /g,"_")

//   return blob
// }

// export const getContainerURL = async ({ azureBlobSAS }) => {
//   const accountName = AZURE_STORAGE_ACCOUNT_NAME
//   const sasString = azureBlobSAS
//   const containerName = AZURE_CONTAINER
//   const containerURL = new window.azblob.ContainerURL(
//     `https://${accountName}.blob.core.windows.net/${containerName}?${sasString}`,
//     window.azblob.StorageURL.newPipeline(new window.azblob.AnonymousCredential())
//   )
//   return containerURL
// }

// // export const uploadFile = async ({ containerURL, currentUserGuid, files }) => {
// //   try {
// //     const promises = []
// //     for (const file of files) {
// //       const fileName = 'radiologists/' + currentUserGuid.replace('|', '-') + '/signature/' + file.name
// //       const blockBlobURL = window.azblob.BlockBlobURL.fromContainerURL(containerURL, fileName)
// //       promises.push(window.azblob.uploadBrowserDataToBlockBlob(window.azblob.Aborter.none, file, blockBlobURL))
// //     }
// //     const values = await Promise.all(promises)
// //     return values
// //   } catch (error) {
// //     console.error(error)
// //   }
// // }


export const uploadFile = async ({ containerURL, file, fileName, showNotificationForUploadInProgress, showNotificationForUploadFailed, notification, hideProgress }) => {
  try {
    var blockSize = 4 * 1024 * 1024
    const blobURL = BlobURL.fromContainerURL(containerURL, fileName);
    const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);
    // const name = `${blobName}-${uuid()}.${
    //   callback.file.name.split('.', -1)[1]
    // }`;
    const response = await uploadBrowserDataToBlockBlob(
        Aborter.none,
        file,
        blockBlobURL,
        {
          blockSize: blockSize,
          progress: function (evt) {
            notification['progress'] = (evt.loadedBytes * 100) / file.size
            if(!hideProgress){
              showNotificationForUploadInProgress(notification)
            }
          }
        }
      )
    return response
  } catch (error) {
    if(!hideProgress){
      showNotificationForUploadFailed(notification)
    }
    console.error(error)
  }
}
