import store from 'store'
import { LOCAL_STORAGE_SESSION_TAG_KEY, LOCAL_STORAGE_SOURCE_PATH, shareLinkPathList } from '../config/constants'
export const prepareDataForSignUp = (sourceData) => {
  if (!sourceData) {
    return {}
  }
  const { userEmail, title, userPhone, userPassword, looking, userFullName, contractType } = sourceData
  const { phone, countryName, dialCode } = userPhone || {};

  return {
    email: userEmail,
    password: userPassword,
    name: userFullName,
    user_metadata: {
      name: userFullName,
      phone_number: phone,
      prefix: dialCode,
      user_type: looking ? looking : contractType,
      title: title,
      home_facility: '',
      specialty: '',
      signature: '',
      country: countryName
    }
  }
}

export const setSessionTag = (email) => {
  const currentDate = new Date()
  // const currentYear = currentDate.getFullYear()
  // const currentMonth = currentDate.getMonth() + 1
  // const currentMonthString = currentMonth < 10 ? '0' + currentMonth : currentMonth
  // const currentDayOfMonth = currentDate.getDate()
  // const currentDateString = currentDayOfMonth < 10 ? '0' + currentDayOfMonth : currentDayOfMonth
  const currentTimestamp = currentDate.getTime()
  // const re = / /gi
  // const dateString = currentYear + '-' + currentMonthString + '-' + currentDateString // currentDate.toDateString().toLowerCase().replace(re, '-')
  // const sessionTag = 'session_' + dateString + '-' + currentTimestamp
  const sessionTag = email + '_on_' + currentTimestamp
  store.set(LOCAL_STORAGE_SESSION_TAG_KEY, sessionTag)
}

export const getSessionTag = () => {
  return store.get(LOCAL_STORAGE_SESSION_TAG_KEY)
}

export const checkShareLink = () => {
  const sourcePath = store.get(LOCAL_STORAGE_SOURCE_PATH) || null;
  return sourcePath ? shareLinkPathList.includes(sourcePath) : false
}