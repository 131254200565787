import { POST_ACCEPT_REQUEST_MEDICAL_GROUP_SUCCESS } from '../actions/medicialGroupActions'
export default (state = {}, { type, data = {} }) => {
  switch (type) {
    case POST_ACCEPT_REQUEST_MEDICAL_GROUP_SUCCESS: {
        return data
    }
    default:
      return state
  }
}
