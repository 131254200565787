import history from '../routers/history'
import store from 'store'
import auth0 from 'auth0-js'
import { AUTH_CONFIG, META_DATA_PREFIX } from '../config/auth0'
import { LOCAL_STORAGE_AUTH_KEY/* , LOCAL_STORAGE_SESSION_TAG_KEY, LOCAL_STORAGE_NEW_ORGANIZATION_KEY */ } from '../config/constants'

export default class Auth {
  constructor () {
    this.auth0 = new auth0.WebAuth(AUTH_CONFIG)
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.getNewTokensForAPI = this.getNewTokensForAPI.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.makeCallToUserInfo = this.makeCallToUserInfo.bind(this)
    this.makeCallToCheckSession = this.makeCallToCheckSession.bind(this)
    this.updateUserAppMetadataInLocalStorage = this.updateUserAppMetadataInLocalStorage.bind(this)
    this.signUp = this.signUp.bind(this)
    this.getUserProfile = this.getUserProfile.bind(this)
    this.updateUserProfile = this.updateUserProfile.bind(this)
  }

  signUp (payload, callback) {
    payload.connection = 'TeleRadUsers'
    this.auth0.signup(payload, callback)
  }

  signupAndAuthorize (payload, callback) {
    payload.connection = 'TeleRadUsers'
    this.auth0.signupAndAuthorize(payload, callback)
  }

  async makeCallToCheckSession () {
    return new Promise((resolve, reject) => this.auth0.checkSession(
      {
        audience: AUTH_CONFIG.audience,
        scope: AUTH_CONFIG.scope
      }, (error, result, response) => {
        if (error) {
          reject(error)
        } else {
          resolve(result, response)
        }
      })
    )
  }

  async getNewTokensForAPI () {
    try {
      const response = await this.makeCallToCheckSession()
        .then((result) => {
          const { accessToken, idToken, state } = result
          store.set(LOCAL_STORAGE_AUTH_KEY.ACCESS_TOKEN, accessToken)
          store.set(LOCAL_STORAGE_AUTH_KEY.ID_TOKEN, idToken)
          store.set(LOCAL_STORAGE_AUTH_KEY.STATE, state)
          return accessToken
        })
      return response
    } catch (e) {
      return e
    }
  }

  updateUserAppMetadataInLocalStorage (appMetadata) {
    let currentProfileInStorage = store.get(LOCAL_STORAGE_AUTH_KEY.USER_PROFILE)
    currentProfileInStorage[META_DATA_PREFIX + 'app_meta_data'] = appMetadata
    store.set(LOCAL_STORAGE_AUTH_KEY.USER_PROFILE, currentProfileInStorage)
  }

  getUserAppMetadata () {
    let currentProfileInStorage = store.get(LOCAL_STORAGE_AUTH_KEY.USER_PROFILE)
    return currentProfileInStorage[META_DATA_PREFIX + 'app_meta_data']
  }

  async makeCallToUserInfo (accessToken) {
    return new Promise((resolve, reject) => this.auth0.client.userInfo(
      accessToken
      , (error, response) => {
        if (error) {
          reject(error)
        } else {
          resolve(response)
        }
      })
    )
  }

  async updateUserProfile (accessToken) {
    try {
      const response = await this.makeCallToUserInfo(accessToken)
        .then((user) => {
          store.set(LOCAL_STORAGE_AUTH_KEY.USER_PROFILE, user)
          return user
        })
      return response
    } catch (error) {
      return error
    }
  }

  getProfileRequestBodyForUpdate () {
    const userProfile = this.getUserProfile()
    if (!userProfile) {
      return null
    }
    const { email, email_verified: emailVerified, name, nickname, picture, sub } = userProfile
    const appMetaData = userProfile && userProfile[META_DATA_PREFIX + 'app_meta_data']
    const userMetaData = userProfile && userProfile[META_DATA_PREFIX + 'user_meta_data']
    return {
      email,
      email_verified: emailVerified,
      name,
      nickname,
      picture,
      sub,
      user_metadata: userMetaData,
      app_metadata: appMetaData
    }
  }

  // Submit the passcode to complete authentication
  // login (username, password, callback) {
  login (username, password, postLoginTarget) {
    store.set(LOCAL_STORAGE_AUTH_KEY.POST_LOGIN_TARGET, postLoginTarget)
    store.set(LOCAL_STORAGE_AUTH_KEY.USER_PASS, password)
    this.auth0.login({
      realm: 'TeleRadUsers',
      username,
      password
    })
  }

  handleAuthentication () {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult, true)
      } else if (err) {
        console.log(err)
        window.alert(`Error: ${err.error}. Check the console for further details.`)
      }
    })
  }

  setSession (authResult, login) {
    // Set the time that the access token will expire at
    let expiresAt = (authResult.expiresIn * 1000) + new Date().getTime()
    store.set(LOCAL_STORAGE_AUTH_KEY.ACCESS_TOKEN, authResult.accessToken)
    store.set(LOCAL_STORAGE_AUTH_KEY.ID_TOKEN, authResult.idToken)
    store.set(LOCAL_STORAGE_AUTH_KEY.EXPIRES_AT, expiresAt)
    store.set(LOCAL_STORAGE_AUTH_KEY.STATE, authResult.state)
    store.remove(LOCAL_STORAGE_AUTH_KEY.USER_PROFILE)
    this.auth0.client.userInfo(authResult.accessToken, (err, user) => {
      if (!err) {
        store.set(LOCAL_STORAGE_AUTH_KEY.USER_PROFILE, user)
        if (login) {
          const postLoginTarget = store.get(LOCAL_STORAGE_AUTH_KEY.POST_LOGIN_TARGET)
          history.push(postLoginTarget)
        }
      }
    })
  }

  logout () {
    // window.localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY.USER_PROFILE)
    // window.localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY.ACCESS_TOKEN)
    // window.localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY.ID_TOKEN)
    // window.localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY.EXPIRES_AT)
    // window.localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY.POST_LOGIN_TARGET)
    // window.localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY.MGMT_ACCESS_TOKEN)
    // window.localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY.MGMT_ID_TOKEN)
    // window.localStorage.removeItem(LOCAL_STORAGE_SESSION_TAG_KEY)
    // window.localStorage.removeItem(LOCAL_STORAGE_NEW_ORGANIZATION_KEY)
    store.clearAll()
  }

  getUserProfile () {
    // const profileValueFromStorage = store.get(LOCAL_STORAGE_AUTH_KEY.USER_PROFILE)
    const profile = store.get(LOCAL_STORAGE_AUTH_KEY.USER_PROFILE)
    return profile
  }

  refreshToken () {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult, true)
      } else if (err) {
        window.alert(`Error: ${err.error}. Check the console for further details.`)
      }
    })
  }

  async getNewToken () {
    const response = await this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult, true)
      } else if (err) {
        window.alert(`Error: ${err.error}. Check the console for further details.`)
      }
    })

    return response
  }

  isAuthenticated () {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = store.get(LOCAL_STORAGE_AUTH_KEY.EXPIRES_AT)
    return new Date().getTime() < expiresAt
  }
}
