import { PUT_STUDY_SHARE_SUCCESS } from '../actions/studyActions'
export default (state = { status: false }, { type, data = {} }) => {
  switch (type) {
    case PUT_STUDY_SHARE_SUCCESS: {
      return { ...state, status: true }
    }
    default:
      return state
  }
}
