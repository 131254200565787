import { all, fork } from 'redux-saga/effects'
import * as countryListSagas from './CountryListSagas'
import * as emailVerificationSagas from './EmailVerificationSagas'
import * as facilitySagas from './FacilitySagas'
import * as groupSagas from './GroupSagas'
import * as inviteSagas from './InviteSagas'
import * as internalRadiologistSagas from './InternalRadiologistSagas'
import * as UserTypeSagas from './UserTypeSagas'
import * as MedicialGroupSagas from './MedicialGroupSagas'
import * as StudySagas from './StudySagas'
import * as UserLocationSagas from './UserLocationSaga'

export default function * rootSaga () {
  yield all([
    ...Object.values(countryListSagas),
    ...Object.values(emailVerificationSagas),
    ...Object.values(facilitySagas),
    ...Object.values(groupSagas),
    ...Object.values(inviteSagas),
    ...Object.values(internalRadiologistSagas),
    ...Object.values(UserTypeSagas),
    ...Object.values(MedicialGroupSagas),
    ...Object.values(StudySagas),
    ...Object.values(UserLocationSagas)    
  ].map(fork))
}
