import { COUNTRY_LIST_ENDPOINT } from '../config/api-endpoints'

export const getCountryListAPICall = async () => {
  try {
    const config = {
      method: 'GET'
    }
    const queryString = '?fields=name,alpha2Code,alpha3Code,callingCodes'
    const response = await window.fetch(COUNTRY_LIST_ENDPOINT + queryString, config)
    const responseOk = await response.status
    const responseJSON = await response.json()
    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}
